import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import Input from '../Input/Input';
import { icons } from '../../Icons/icons';

export const SearchInput = ({
  label,
  value,
  onChange,
  onSearch,
  placeholder,
  className,
  error,
  info,
  disabled,
  onFocus,
  onBlur,
  iconOnText,
  ...props
}) => {
  const updateIcon = useCallback((value) => (value ? iconOnText : icons.search), [iconOnText]);
  const [icon, setIcon] = useState(updateIcon(value));

  useEffect(() => {
    setIcon(updateIcon(value));
  }, [updateIcon, value]);

  const handleChange = (e) => {
    setIcon(updateIcon(e.target.value));
    onChange(e);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSearch();
    }
  };

  return (
    <Input
      tag="input"
      label={label}
      value={value}
      onChange={handleChange}
      onFocus={onFocus}
      onBlur={onBlur}
      placeholder={placeholder}
      className={className}
      error={error}
      info={info}
      disabled={disabled}
      icon={icon}
      onIconClick={icon === iconOnText ? onSearch : () => {}}
      onKeyPress={handleKeyPress}
      {...props}
    />
  );
};

SearchInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  info: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  onChange: PropTypes.func,
  iconOnText: PropTypes.string,
};

SearchInput.defaultProps = {
  placeholder: 'Buscar...',
  info: null,
  error: null,
  disabled: false,
  onFocus: () => {},
  onBlur: () => {},
  onChange: () => {},
  className: '',
  iconOnText: icons.forward,
};
