import { colors } from '../../styles/colors';
import { respondTo } from '../../styles/respondTo';

export const styles = {
  header: {
    position: 'fixed',
    zIndex: 50,
    top: 0,
    left: 0,
    height: 48,
    width: '100%',
    background: '#fff',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2)',

    '.breadcrumb': {
      marginLeft: 72,
      [respondTo('md')]: {
        marginLeft: 104,
      },
    },
  },
  '.placeholder': {
    display: 'block',
    height: 48,
    position: 'static',
    border: '20px silid red',
  },

  '&.menu-open': {
    '.toggle-menu': {
      left: 'calc(100vW - 56px)',
    },
    '.menu-container': {
      left: 0,
      opacity: 1,
    },
  },

  '.toggle-menu': {
    width: 56,
    height: 48,
    padding: 0,
    position: 'relative',
    zIndex: 3,
    transition: 'left 0.2s',
    left: 0,
    background: '#fff',
    border: 0,
    borderRight: `1px solid ${colors.gray.lightest}`,
    outline: 'none',
    verticalAlign: 'top',
    cursor: 'pointer',
  },
  '.logo-container': {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    a: {
      lineHeight: '48px',
      img: {
        height: 32,
        verticalAlign: 'middle',
      },
    },
  },
  '.menu-container': {
    borderRight: `1px solid ${colors.gray.lighten}`,
    background: '#fff',
    width: 'calc(100vW - 56px)',
    height: '100vh',
    transition: 'all 0.2s',
    position: 'fixed',
    top: 0,
    left: 'calc(-100vW + 56px)',
    zIndex: 1000,
    opacity: 0,
    overflow: 'auto',
  },
  '.click-outside': {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
    zIndex: 2,
    outline: 'none',
  },
  [respondTo('md')]: {
    header: { height: 60 },
    '.placeholder': { height: 60 },
    '.toggle-menu': {
      width: 80,
      height: 60,
      left: 0,
      '.udt-icon': {
        width: 32,
        height: 32,
      },
    },
    '&.menu-open': {
      '.toggle-menu': {
        left: 300,
      },
      '.menu-container': {
        left: 0,
      },
    },
    '.logo-container': {
      a: {
        lineHeight: '60px',
        img: {
          height: 44,
        },
      },
    },
    '.menu-container': {
      width: 300,
      left: -300,
    },
  },
};
