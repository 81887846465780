/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import { baseLink } from '../../styles/baseStyles';
import { subtitleFont } from '../../styles/typography';
import { colors } from '../../styles/colors';
import Card from './Card';
import { TableHeader } from './TableHeader';

const border = `1px solid ${colors.gray.light}`;
const styles = {
  width: '100%',
  borderCollapse: 'collapse',
  border,
  backgroundColor: colors.gray.ultralight,
  '&.fixed-layout': {
    tableLayout: 'fixed',
  },
  'th, td': {
    textAlign: 'left',
    border,
  },

  th: {
    padding: '14px 20px 14px 8px',
    textTransform: 'uppercase',
    ...subtitleFont('sm', 'sans'),
    color: colors.gray.darkest,
    position: 'relative',
    '.icons': {
      position: 'absolute',
      right: 6,
      top: '25%',
      height: 20,
      display: 'grid',
      justifyContent: 'center',
      alignItems: 'center',
      svg: {
        display: 'block',
        cursor: 'pointer',
      },
    },
  },

  td: {
    padding: '10px 8px',
    ...subtitleFont('md', 'sans'),
    fontWeight: 'normal',
    color: colors.gray.darkest,
    backgroundColor: 'white',
    '&:first-of-type': {
      a: {
        ...baseLink,
      },
    },
  },

  tr: {
    '&:hover': {
      backgroundColor: colors.cyan.lightest,
    },
  },

  '&.transparent-column': {
    border: 0,
    'thead th:last-child': {
      backgroundColor: 'white',
      border: 0,
      '.icons': {
        display: 'none',
      },
    },
    'tbody td:last-child': {
      backgroundColor: 'white',
      border: 0,
    },
  },
};

export const OrderedList = ({
  headers,
  rows,
  className,
  onOrderClick,
  fixedLayout,
  isTransparentColumn,
  dropHandler,
}) => {
  const [cards, setCards] = useState([]);

  const moveCard = (dragIndex, hoverIndex) => {
    const dragCard = cards[dragIndex];
    setCards(
      update(cards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      }),
    );
  };

  useEffect(() => {
    setCards(rows);
  }, [rows]);

  return (
    <DndProvider backend={HTML5Backend}>
      <table
        css={styles}
        className={`${isTransparentColumn ? 'transparent-column' : ''} ${
          fixedLayout ? 'fixed-layout' : ''
        } ${className}`}
      >
        <thead>
          <tr>
            {headers.map((header) => (
              <TableHeader
                key={`thead_${header.id}`}
                width={header.width}
                title={header.title}
                id={header.id}
                onOrderClick={onOrderClick}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {cards.map((card, i) => (
            <Card
              key={`car_${card.id}`}
              index={i}
              id={card.id}
              headers={headers}
              row={card}
              moveCard={moveCard}
              endDrag={() => dropHandler(cards)}
            />
          ))}
        </tbody>
      </table>
    </DndProvider>
  );
};

OrderedList.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      render: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
      width: PropTypes.number,
    }),
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
  onOrderClick: PropTypes.func,
  fixedLayout: PropTypes.bool,
  isTransparentColumn: PropTypes.bool,
  dropHandler: PropTypes.func.isRequired,
};

OrderedList.defaultProps = {
  onOrderClick: undefined,
  className: '',
  isTransparentColumn: false,
  fixedLayout: true,
};
