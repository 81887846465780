//Libreria hint.css https://kushagra.dev/lab/hint/
export const tooltipStyles = {
  '[class*="hint--"]': { position: 'relative', display: 'inline-block' },
  '[class*="hint--"]:before, [class*="hint--"]:after': {
    position: 'absolute',
    WebkitTransform: 'translate3d(0, 0, 0)',
    MozTransform: 'translate3d(0, 0, 0)',
    transform: 'translate3d(0, 0, 0)',
    visibility: 'hidden',
    opacity: 0,
    zIndex: 1000000,
    pointerEvents: 'none',
    WebkitTransition: '0.3s ease',
    MozTransition: '0.3s ease',
    transition: '0.3s ease',
    WebkitTransitionDelay: '0ms',
    MozTransitionDelay: '0ms',
    transitionDelay: '0ms',
  },
  '[class*="hint--"]:hover:before, [class*="hint--"]:hover:after': {
    visibility: 'visible',
    opacity: 1,
    WebkitTransitionDelay: '100ms',
    MozTransitionDelay: '100ms',
    transitionDelay: '100ms',
  },
  '[class*="hint--"]:before': {
    content: "''",
    position: 'absolute',
    background: 'transparent',
    border: '6px solid transparent',
    zIndex: 1000001,
  },
  '[class*="hint--"]:after': {
    background: '#383838',
    color: 'white',
    padding: '8px 10px',
    fontSize: '12px',
    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
    lineHeight: '12px',
    whiteSpace: 'nowrap',
    textShadow: '0 -1px 0px black',
    boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.3)',
  },
  '[class*="hint--"][aria-label]:after': { content: 'attr(aria-label)' },
  '[class*="hint--"][data-hint]:after': { content: 'attr(data-hint)' },
  "[aria-label='']:before, [aria-label='']:after,\n[data-hint='']:before,\n[data-hint='']:after": {
    display: 'none !important',
  },
  '.hint--top-left:before': {
    borderTopColor: '#383838',
    marginBottom: '-11px',
    left: 'calc(50% - 6px)',
  },
  '.hint--top-right:before': {
    borderTopColor: '#383838',
    marginBottom: '-11px',
    left: 'calc(50% - 6px)',
  },
  '.hint--top:before': {
    borderTopColor: '#383838',
    marginBottom: '-11px',
    left: 'calc(50% - 6px)',
  },
  '.hint--bottom-left:before': {
    borderBottomColor: '#383838',
    marginTop: '-11px',
    left: 'calc(50% - 6px)',
  },
  '.hint--bottom-right:before': {
    borderBottomColor: '#383838',
    marginTop: '-11px',
    left: 'calc(50% - 6px)',
  },
  '.hint--bottom:before': {
    borderBottomColor: '#383838',
    marginTop: '-11px',
    left: 'calc(50% - 6px)',
  },
  '.hint--left:before': {
    borderLeftColor: '#383838',
    marginRight: '-11px',
    marginBottom: '-6px',
  },
  '.hint--right:before': {
    borderRightColor: '#383838',
    marginLeft: '-11px',
    marginBottom: '-6px',
  },
  '.hint--top:before, .hint--top:after': { bottom: '100%', left: '50%' },
  '.hint--top:after': {
    WebkitTransform: 'translateX(-50%)',
    MozTransform: 'translateX(-50%)',
    transform: 'translateX(-50%)',
  },
  '.hint--top:hover:before': {
    WebkitTransform: 'translateY(-8px)',
    MozTransform: 'translateY(-8px)',
    transform: 'translateY(-8px)',
  },
  '.hint--top:hover:after': {
    WebkitTransform: 'translateX(-50%) translateY(-8px)',
    MozTransform: 'translateX(-50%) translateY(-8px)',
    transform: 'translateX(-50%) translateY(-8px)',
  },
  '.hint--bottom:before, .hint--bottom:after': { top: '100%', left: '50%' },
  '.hint--bottom:after': {
    WebkitTransform: 'translateX(-50%)',
    MozTransform: 'translateX(-50%)',
    transform: 'translateX(-50%)',
  },
  '.hint--bottom:hover:before': {
    WebkitTransform: 'translateY(8px)',
    MozTransform: 'translateY(8px)',
    transform: 'translateY(8px)',
  },
  '.hint--bottom:hover:after': {
    WebkitTransform: 'translateX(-50%) translateY(8px)',
    MozTransform: 'translateX(-50%) translateY(8px)',
    transform: 'translateX(-50%) translateY(8px)',
  },
  '.hint--right:after': { marginBottom: '-14px' },
  '.hint--right:before, .hint--right:after': { left: '100%', bottom: '50%' },
  '.hint--right:hover:before': {
    WebkitTransform: 'translateX(8px)',
    MozTransform: 'translateX(8px)',
    transform: 'translateX(8px)',
  },
  '.hint--right:hover:after': {
    WebkitTransform: 'translateX(8px)',
    MozTransform: 'translateX(8px)',
    transform: 'translateX(8px)',
  },
  '.hint--left:after': { marginBottom: '-14px' },
  '.hint--left:before, .hint--left:after': { right: '100%', bottom: '50%' },
  '.hint--left:hover:before': {
    WebkitTransform: 'translateX(-8px)',
    MozTransform: 'translateX(-8px)',
    transform: 'translateX(-8px)',
  },
  '.hint--left:hover:after': {
    WebkitTransform: 'translateX(-8px)',
    MozTransform: 'translateX(-8px)',
    transform: 'translateX(-8px)',
  },
  '.hint--top-left:before, .hint--top-left:after': {
    bottom: '100%',
    left: '50%',
  },
  '.hint--top-left:after': {
    WebkitTransform: 'translateX(-100%)',
    MozTransform: 'translateX(-100%)',
    transform: 'translateX(-100%)',
    marginLeft: '12px',
  },
  '.hint--top-left:hover:before': {
    WebkitTransform: 'translateY(-8px)',
    MozTransform: 'translateY(-8px)',
    transform: 'translateY(-8px)',
  },
  '.hint--top-left:hover:after': {
    WebkitTransform: 'translateX(-100%) translateY(-8px)',
    MozTransform: 'translateX(-100%) translateY(-8px)',
    transform: 'translateX(-100%) translateY(-8px)',
  },
  '.hint--top-right:before, .hint--top-right:after': {
    bottom: '100%',
    left: '50%',
  },
  '.hint--top-right:after': {
    WebkitTransform: 'translateX(0)',
    MozTransform: 'translateX(0)',
    transform: 'translateX(0)',
    marginLeft: '-12px',
  },
  '.hint--top-right:hover:before': {
    WebkitTransform: 'translateY(-8px)',
    MozTransform: 'translateY(-8px)',
    transform: 'translateY(-8px)',
  },
  '.hint--top-right:hover:after': {
    WebkitTransform: 'translateY(-8px)',
    MozTransform: 'translateY(-8px)',
    transform: 'translateY(-8px)',
  },
  '.hint--bottom-left:before, .hint--bottom-left:after': {
    top: '100%',
    left: '50%',
  },
  '.hint--bottom-left:after': {
    WebkitTransform: 'translateX(-100%)',
    MozTransform: 'translateX(-100%)',
    transform: 'translateX(-100%)',
    marginLeft: '12px',
  },
  '.hint--bottom-left:hover:before': {
    WebkitTransform: 'translateY(8px)',
    MozTransform: 'translateY(8px)',
    transform: 'translateY(8px)',
  },
  '.hint--bottom-left:hover:after': {
    WebkitTransform: 'translateX(-100%) translateY(8px)',
    MozTransform: 'translateX(-100%) translateY(8px)',
    transform: 'translateX(-100%) translateY(8px)',
  },
  '.hint--bottom-right:before, .hint--bottom-right:after': {
    top: '100%',
    left: '50%',
  },
  '.hint--bottom-right:after': {
    WebkitTransform: 'translateX(0)',
    MozTransform: 'translateX(0)',
    transform: 'translateX(0)',
    marginLeft: '-12px',
  },
  '.hint--bottom-right:hover:before': {
    WebkitTransform: 'translateY(8px)',
    MozTransform: 'translateY(8px)',
    transform: 'translateY(8px)',
  },
  '.hint--bottom-right:hover:after': {
    WebkitTransform: 'translateY(8px)',
    MozTransform: 'translateY(8px)',
    transform: 'translateY(8px)',
  },
  '.hint--small:after,\n.hint--medium:after,\n.hint--large:after': {
    whiteSpace: 'normal',
    lineHeight: '1.4em',
    wordWrap: 'break-word',
  },
  '.hint--small:after': { width: '80px' },
  '.hint--medium:after': { width: '150px' },
  '.hint--large:after': { width: '300px' },
  '.hint--error:after': {
    backgroundColor: '#b34e4d',
    textShadow: '0 -1px 0px #592726',
  },
  '.hint--error.hint--top-left:before': { borderTopColor: '#b34e4d' },
  '.hint--error.hint--top-right:before': { borderTopColor: '#b34e4d' },
  '.hint--error.hint--top:before': { borderTopColor: '#b34e4d' },
  '.hint--error.hint--bottom-left:before': { borderBottomColor: '#b34e4d' },
  '.hint--error.hint--bottom-right:before': { borderBottomColor: '#b34e4d' },
  '.hint--error.hint--bottom:before': { borderBottomColor: '#b34e4d' },
  '.hint--error.hint--left:before': { borderLeftColor: '#b34e4d' },
  '.hint--error.hint--right:before': { borderRightColor: '#b34e4d' },
  '.hint--warning:after': {
    backgroundColor: '#c09854',
    textShadow: '0 -1px 0px #6c5328',
  },
  '.hint--warning.hint--top-left:before': { borderTopColor: '#c09854' },
  '.hint--warning.hint--top-right:before': { borderTopColor: '#c09854' },
  '.hint--warning.hint--top:before': { borderTopColor: '#c09854' },
  '.hint--warning.hint--bottom-left:before': { borderBottomColor: '#c09854' },
  '.hint--warning.hint--bottom-right:before': { borderBottomColor: '#c09854' },
  '.hint--warning.hint--bottom:before': { borderBottomColor: '#c09854' },
  '.hint--warning.hint--left:before': { borderLeftColor: '#c09854' },
  '.hint--warning.hint--right:before': { borderRightColor: '#c09854' },
  '.hint--info:after': {
    backgroundColor: '#3986ac',
    textShadow: '0 -1px 0px #1a3c4d',
  },
  '.hint--info.hint--top-left:before': { borderTopColor: '#3986ac' },
  '.hint--info.hint--top-right:before': { borderTopColor: '#3986ac' },
  '.hint--info.hint--top:before': { borderTopColor: '#3986ac' },
  '.hint--info.hint--bottom-left:before': { borderBottomColor: '#3986ac' },
  '.hint--info.hint--bottom-right:before': { borderBottomColor: '#3986ac' },
  '.hint--info.hint--bottom:before': { borderBottomColor: '#3986ac' },
  '.hint--info.hint--left:before': { borderLeftColor: '#3986ac' },
  '.hint--info.hint--right:before': { borderRightColor: '#3986ac' },
  '.hint--success:after': {
    backgroundColor: '#458746',
    textShadow: '0 -1px 0px #1a321a',
  },
  '.hint--success.hint--top-left:before': { borderTopColor: '#458746' },
  '.hint--success.hint--top-right:before': { borderTopColor: '#458746' },
  '.hint--success.hint--top:before': { borderTopColor: '#458746' },
  '.hint--success.hint--bottom-left:before': { borderBottomColor: '#458746' },
  '.hint--success.hint--bottom-right:before': { borderBottomColor: '#458746' },
  '.hint--success.hint--bottom:before': { borderBottomColor: '#458746' },
  '.hint--success.hint--left:before': { borderLeftColor: '#458746' },
  '.hint--success.hint--right:before': { borderRightColor: '#458746' },
  '.hint--always:after, .hint--always:before': {
    opacity: 1,
    visibility: 'visible',
  },
  '.hint--always.hint--top:before': {
    WebkitTransform: 'translateY(-8px)',
    MozTransform: 'translateY(-8px)',
    transform: 'translateY(-8px)',
  },
  '.hint--always.hint--top:after': {
    WebkitTransform: 'translateX(-50%) translateY(-8px)',
    MozTransform: 'translateX(-50%) translateY(-8px)',
    transform: 'translateX(-50%) translateY(-8px)',
  },
  '.hint--always.hint--top-left:before': {
    WebkitTransform: 'translateY(-8px)',
    MozTransform: 'translateY(-8px)',
    transform: 'translateY(-8px)',
  },
  '.hint--always.hint--top-left:after': {
    WebkitTransform: 'translateX(-100%) translateY(-8px)',
    MozTransform: 'translateX(-100%) translateY(-8px)',
    transform: 'translateX(-100%) translateY(-8px)',
  },
  '.hint--always.hint--top-right:before': {
    WebkitTransform: 'translateY(-8px)',
    MozTransform: 'translateY(-8px)',
    transform: 'translateY(-8px)',
  },
  '.hint--always.hint--top-right:after': {
    WebkitTransform: 'translateY(-8px)',
    MozTransform: 'translateY(-8px)',
    transform: 'translateY(-8px)',
  },
  '.hint--always.hint--bottom:before': {
    WebkitTransform: 'translateY(8px)',
    MozTransform: 'translateY(8px)',
    transform: 'translateY(8px)',
  },
  '.hint--always.hint--bottom:after': {
    WebkitTransform: 'translateX(-50%) translateY(8px)',
    MozTransform: 'translateX(-50%) translateY(8px)',
    transform: 'translateX(-50%) translateY(8px)',
  },
  '.hint--always.hint--bottom-left:before': {
    WebkitTransform: 'translateY(8px)',
    MozTransform: 'translateY(8px)',
    transform: 'translateY(8px)',
  },
  '.hint--always.hint--bottom-left:after': {
    WebkitTransform: 'translateX(-100%) translateY(8px)',
    MozTransform: 'translateX(-100%) translateY(8px)',
    transform: 'translateX(-100%) translateY(8px)',
  },
  '.hint--always.hint--bottom-right:before': {
    WebkitTransform: 'translateY(8px)',
    MozTransform: 'translateY(8px)',
    transform: 'translateY(8px)',
  },
  '.hint--always.hint--bottom-right:after': {
    WebkitTransform: 'translateY(8px)',
    MozTransform: 'translateY(8px)',
    transform: 'translateY(8px)',
  },
  '.hint--always.hint--left:before': {
    WebkitTransform: 'translateX(-8px)',
    MozTransform: 'translateX(-8px)',
    transform: 'translateX(-8px)',
  },
  '.hint--always.hint--left:after': {
    WebkitTransform: 'translateX(-8px)',
    MozTransform: 'translateX(-8px)',
    transform: 'translateX(-8px)',
  },
  '.hint--always.hint--right:before': {
    WebkitTransform: 'translateX(8px)',
    MozTransform: 'translateX(8px)',
    transform: 'translateX(8px)',
  },
  '.hint--always.hint--right:after': {
    WebkitTransform: 'translateX(8px)',
    MozTransform: 'translateX(8px)',
    transform: 'translateX(8px)',
  },
  '.hint--rounded:after': { borderRadius: '4px' },
  '.hint--no-animate:before, .hint--no-animate:after': {
    WebkitTransitionDuration: '0ms',
    MozTransitionDuration: '0ms',
    transitionDuration: '0ms',
  },
  '.hint--bounce:before, .hint--bounce:after': {
    WebkitTransition:
      'opacity 0.3s ease, visibility 0.3s ease, -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24)',
    MozTransition:
      'opacity 0.3s ease, visibility 0.3s ease, -moz-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24)',
    transition:
      'opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24)',
  },
  '.hint--no-shadow:before, .hint--no-shadow:after': {
    textShadow: 'initial',
    boxShadow: 'initial',
  },
};
