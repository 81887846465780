import PropTypes from 'prop-types';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import Input from '../Input/Input';

export const DateInput = ({
  label,
  required,
  value,
  onChange,
  placeholder,
  className,
  error,
  info,
  disabled,
  onFocus,
  onBlur,
  ...props
}) => {
  return (
    <Input
      label={label}
      required={required}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      placeholder={placeholder}
      className={className}
      error={error}
      info={info}
      disabled={disabled}
      mask="a9/b9/cd99"
      formatChars={{
        a: '[0-3]',
        b: '[0-1]',
        c: '[1-2]',
        d: '[0-9]',
        9: '[0-9]',
      }}
      {...props}
    />
  );
};

DateInput.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  info: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

DateInput.defaultProps = {
  placeholder: null,
  info: null,
  error: null,
  disabled: false,
  required: false,
  onFocus: () => {},
  onBlur: () => {},
  onChange: () => {},
  className: '',
};
