/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { colors } from '../../styles/colors';
import Icon from '../Icons/Icon';
import { icons } from '../Icons/icons';
import { styles } from './styles';
import { DefaultHeaderChildren } from './DefaultHeaderChildren';

const Header = ({
  children,
  menuTitle,
  menuContent,
  menuEnabled,
  closedFromOutside,
  className,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    setMenuOpen(false);
  }, [closedFromOutside]);

  return (
    <div css={styles} className={`${className} ${menuOpen ? 'menu-open' : ''}`}>
      <div className="placeholder" />
      <header css={styles}>
        {menuEnabled && (
          <button
            type="button"
            className="toggle-menu"
            aria-label="main menu"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <Icon icon={icons.menu} className="hamburguer" color={colors.cyan.dark} size="24" />
          </button>
        )}
        <div className="logo-container">{children}</div>
        <div className="menu-container">
          {menuTitle}
          {menuContent}
        </div>
        {menuOpen ? (
          <div
            className="click-outside"
            onClick={() => setMenuOpen(!menuOpen)}
            onKeyDown={() => setMenuOpen(!menuOpen)}
            role="button"
            tabIndex="0"
          />
        ) : undefined}
      </header>
    </div>
  );
};

Header.propTypes = {
  children: PropTypes.node,
  menuTitle: PropTypes.node,
  menuContent: PropTypes.node,
  menuEnabled: PropTypes.bool,
  closedFromOutside: PropTypes.bool,
  className: PropTypes.string,
};

Header.defaultProps = {
  menuTitle: undefined,
  menuContent: undefined,
  menuEnabled: true,
  closedFromOutside: false,
  children: <DefaultHeaderChildren />,
  className: '',
};

export default Header;
