/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { colors } from '../../styles/colors';

const style = {
  display: 'flex',
  padding: '12px 16px',
  justifyContent: 'space-between',
  alignItems: 'center',
  '.logo': {
    width: 37,
    height: 32,
    objectFit: 'contain',
  },
  borderBottom: `1px solid ${colors.gray.lighten}`,
};

export const MenuTitle = ({ to, className }) => {
  return (
    <div css={style} className={`menu-title ${className}`}>
      <Link to={to}>
        <img
          className="logo"
          src="https://branding.123seguro.com/logotypes/123/color/logo_123seguro.svg"
          alt="123Seguro"
          title="123Seguro"
        />
      </Link>
    </div>
  );
};

MenuTitle.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
};

MenuTitle.defaultProps = {
  to: '/',
  className: '',
};
