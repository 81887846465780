/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { icons } from '../../Icons/icons';
import Icon from '../../Icons/Icon';
import { colors, colorTheme } from '../../../styles/colors';
import Button from '../../Button/Button';
import { ButtonWrapper } from '../../ButtonWrapper/ButtonWrapper';
import { range } from '../../../utils/data/range';
import { getYearRangeLimits } from './date-range-limits';

const Styles = {
  '.grid-years': {
    gridTemplateColumns: 'repeat(5, 60px)',
    gridTemplateRows: 'repeat(5, 52px)',
    display: 'grid',
    margin: 0,
    padding: '0 14px 0 14px',
    li: {
      listStyle: 'none',
      width: 60,
      height: 52,
      textAlign: 'center',
      paddingTop: 18,
    },
  },
  '.page-options-wrapper': {
    textAlign: 'center',
    background: colors.blue.lightest,
    '.page-options': {
      display: 'inlineBlock',
    },
  },
};

const getYearsInPages = (limits, currentdate, yearsByPage = 25) => {
  const [start, end] = getYearRangeLimits(limits, currentdate);

  const years = range(start, end);
  const pages = Math.ceil(years.length / yearsByPage);

  const yearsInPages = [];
  for (let page = 0; page < pages; page++) {
    const yearsInPage = years.slice(page * yearsByPage, (page + 1) * yearsByPage);
    yearsInPages.push(yearsInPage);
  }
  return yearsInPages;
};

const YearsSelect = ({ selectYear, limits, currentDate }) => {
  const [pageSelected, setPageSelected] = useState(0);
  const [initialized, setInitialized] = useState(false);

  const yearsInPages = getYearsInPages(limits, currentDate);

  useEffect(() => {
    if (!initialized) {
      const initialPage = Math.max(0, yearsInPages.length - 2);
      setPageSelected(initialPage);
      setInitialized(true);
    }
  }, [pageSelected, initialized, yearsInPages.length]);

  const nextPage = () => {
    if (pageSelected < yearsInPages.length - 1) {
      setPageSelected(pageSelected + 1);
    }
  };

  const previusPage = () => {
    if (pageSelected !== 0) {
      setPageSelected(pageSelected - 1);
    }
  };

  return (
    <div css={Styles}>
      <ul className="grid-years">
        {yearsInPages[pageSelected].map((year) => (
          <li key={year}>
            {
              <Button variant="link" size="sm" onClick={() => selectYear(year)}>
                {year}
              </Button>
            }
          </li>
        ))}
      </ul>
      <div className="page-options-wrapper">
        <div className="page-options">
          <ButtonWrapper onClick={previusPage}>
            <Icon icon={icons.chevronTop} size={32} color={colorTheme.hintInteractive} />
          </ButtonWrapper>
          <ButtonWrapper onClick={nextPage}>
            <Icon icon={icons.chevronBottom} size={32} color={colorTheme.hintInteractive} />
          </ButtonWrapper>
        </div>
      </div>
    </div>
  );
};

YearsSelect.propTypes = {
  selectYear: PropTypes.func.isRequired,
  limits: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
  }),
  currentDate: PropTypes.instanceOf(Date).isRequired,
};

export default YearsSelect;
