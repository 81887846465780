import PropTypes from 'prop-types';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { ButtonSelector } from '../ButtonSelector/ButtonSelector';
import { normalizeRender } from '../../utils/data/render';
import { buttonSelectorListStyles } from './buttonSelectorListStyles';

export const ButtonSelectorOptions = ({ items, render, onClick, className = '' }) => {
  const normalizedRender = normalizeRender(render);

  return (
    <div css={{ ...buttonSelectorListStyles }} className={`button-selector-options ${className}`}>
      {items.map((item, i) => (
        <ButtonSelector key={i} onClick={() => onClick(item)}>
          {normalizedRender(item)}
        </ButtonSelector>
      ))}
    </div>
  );
};

ButtonSelectorOptions.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
  onClick: PropTypes.func.isRequired,
  render: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

ButtonSelectorOptions.defaultProps = {
  className: '',
  render: null,
};
