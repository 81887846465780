import PropTypes from 'prop-types';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import Icon from '../Icons/Icon';
import { icons } from '../Icons/icons';
import { colors } from '../../styles/colors';
import { bodyFont } from '../../styles/typography';

const styles = {
  ...bodyFont('md', 'sans'),
  cursor: 'pointer',
  display: 'flex',
  textAlign: 'left',
  width: '100%',
  position: 'relative',
  padding: '14px 40px 14px 16px',
  color: colors.cyan.dark,
  border: `1px solid ${colors.gray.light}`,
  backgroundColor: 'white',

  svg: {
    position: 'absolute',
    top: '25%',
    right: 8,
  },
};

export const ButtonSelector = ({ children, onClick, className, ...props }) => {
  return (
    // eslint-disable-next-line react/button-has-type
    <button css={styles} onClick={onClick} className={`ui-button-selector ${className}`} {...props}>
      {children}
      <Icon icon={icons.chevronRight} color={colors.cyan.dark} size={24} />
    </button>
  );
};

ButtonSelector.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

ButtonSelector.defaultProps = {
  className: '',
};
