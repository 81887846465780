/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { colors, colorTheme } from '../../styles/colors';
import { bodyFont } from '../../styles/typography';
import Icon from '../Icons/Icon';
import { icons } from '../Icons/icons';
import { Loader } from '../Loader/Loader';

const baseStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: 310,
  height: 48,
  backgroundColor: colors.gray.ultralight,
  border: `1px solid ${colors.gray.lighten}`,
  borderLeft: '4px solid transparent',
  paddingLeft: 12,
  paddingRight: 12,
  '&:disabled': {
    color: colorTheme.textLight,
    '&:hover': { cursor: 'default' },
  },
  ...bodyFont('md', 'serif'),
};

const getStyles = (selected) =>
  selected
    ? {
        ...baseStyles,
        color: colors.cyan.dark,
        backgroundColor: colors.cyan.lightest,
        borderLeftColor: colors.cyan.dark,
      }
    : baseStyles;

const stateIcon = {
  fulfilled: {
    color: colors.green.dark,
    icon: icons.check,
  },
  error: {
    color: colors.red.dark,
    icon: icons.error,
  },
};

const State = ({ state }) => {
  if (state === 'loading')
    return (
      <div className="loader-container">
        <Loader size="sm" />
      </div>
    );

  const iconProps = stateIcon[state];
  return iconProps ? <Icon size="md" {...iconProps} /> : null;
};

const Step = ({ id, state, title, selected, onClick, className }) => {
  return (
    <button
      onClick={() => onClick(id)}
      css={getStyles(selected)}
      disabled={state === 'disabled'}
      className={className}
    >
      <span>{title}</span>
      <State state={state} />
    </button>
  );
};

State.propTypes = {
  state: PropTypes.oneOf(['unfulfilled', 'fulfilled', 'error', 'disabled', 'loading']).isRequired,
};

Step.propTypes = {
  id: PropTypes.string.isRequired,
  state: PropTypes.oneOf(['unfulfilled', 'fulfilled', 'error', 'disabled', 'loading']).isRequired,
  title: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

Step.defaultProps = {
  selected: false,
  className: '',
  onClick: (id) => {},
};

export default Step;
