const countryInitialState = [
  {
    country: 'ar',
    name: 'Argentina',
    flagUrl: 'https://branding.123seguro.com/flags/flag_argentina.svg',
    className: 'qa-bandera-argentina',
  },
  {
    country: 'br',
    name: 'Brasil',
    flagUrl: 'https://branding.123seguro.com/flags/flag_brasil.svg',
    className: 'qa-bandera-brasil',
  },
  {
    country: 'cl',
    name: 'Chile',
    flagUrl: 'https://branding.123seguro.com/flags/flag_chile.svg',
    className: 'qa-bandera-chile',
  },
  {
    country: 'co',
    name: 'Colombia',
    flagUrl: 'https://branding.123seguro.com/flags/flag_colombia.svg',
    className: 'qa-bandera-colombia',
  },
];

export default countryInitialState;
