import PropTypes from 'prop-types';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';

const styles = {
  padding: 0,
  border: 'none',
  lineHeight: 0,
  backgroundColor: 'transparent',
  outline: 'none',
  cursor: 'pointer',
};

export const ButtonWrapper = ({ children, className, onClick, type, ...props }) => {
  return (
    // eslint-disable-next-line react/button-has-type
    <button css={styles} onClick={onClick} className={className} type={type} {...props}>
      {children}
    </button>
  );
};

ButtonWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
};

ButtonWrapper.defaultProps = {
  className: 'button-wrapper',
  type: 'button',
};
