import PropTypes from 'prop-types';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Link } from 'react-router-dom';
import { colors } from '../../styles/colors';
import { bodyFont } from '../../styles/typography';

const normalColor = colors.gray.darkest;
const activeColor = colors.cyan.dark;

const styles = {
  position: 'relative',
  padding: '15px 40px 15px 24px',
  color: normalColor,
  backgroundColor: 'white',
  ...bodyFont('sm'),

  '.sub-menu-item': {
    paddingLeft: 40,
  },

  '.title': {
    fontSize: 13,
    '&:hover': {
      color: activeColor,
    },
  },

  '.active': {
    color: activeColor,
    '.title': {
      color: activeColor,
    },
  },

  a: {
    color: 'inherit',
    textDecoration: 'none',
  },
};

export const MenuItem = ({
  title,
  secondaryTitle,
  path,
  closeMenu,
  sub = false,
  active = false,
  className = '',
}) => {
  return (
    <div css={styles}>
      <Link
        onClick={closeMenu}
        to={path}
        className={`${sub ? 'sub-menu-item' : ''} ${active ? 'active' : ''} ${className}`}
      >
        <span className="title">{title}</span>
        {secondaryTitle && <span className="secondaryTitle">{secondaryTitle}</span>}
      </Link>
    </div>
  );
};

MenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  secondaryTitle: PropTypes.string,
  path: PropTypes.string.isRequired,
  closeMenu: PropTypes.func.isRequired,
  sub: PropTypes.bool,
  active: PropTypes.bool,
  className: PropTypes.string,
};

MenuItem.defaultProps = {
  secondaryTitle: '',
  sub: false,
  active: false,
  className: '',
};
