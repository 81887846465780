export const makeMeContainer = (containerMaxWidth) => {
  return {
    maxWidth: containerMaxWidth,
    width: '100%',
    margin: '0 auto',
    boxSizing: 'border-box',
  };
};

export const makeMeRow = (rowGutter = 0) => {
  return {
    boxSizing: 'border-box',
    display: 'flex',
    flex: ' 0 1 auto',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: `0 ${(rowGutter / 2) * -1}px`,
  };
};

export const columnCommon = (columnGutter) => {
  return { boxSizing: 'border-box', flex: '0 0 auto', padding: `0 ${columnGutter / 2}px` };
};

// Makes an element a column
// @group Grid System
// @param {Num} columnCount [none] - Column range (N of X)
// @param {Num} columnTotal [none] - Total columns in the grid (X)
// @param {size} columnGutter [none] - Total gap between columns. IE: 16px (8px +8px)
export const makeMeColumn = (columnCount, columnTotal, columnGutter) => {
  return {
    ...columnCommon(columnGutter),
    width: `${(columnCount * 100) / columnTotal}%`,
  };
};

// Utils

export const rowReverse = () => {
  return {
    flexDirection: 'row-reverse',
  };
};

export const columnFirst = () => {
  return { order: -1 };
};

export const columnLast = () => {
  return { order: 1 };
};

// Aligment
// @group Grid System
// @param {string} aling [none] - 'start', 'center', 'end' 'top','middle','bottom' and stretch

export const rowAlign = (align) => {
  const aligns = {
    start: {
      justifyContent: 'flex-start',
      textAlign: 'start',
    },
    center: {
      justifyContent: 'center',
      textAlign: 'center',
    },
    end: {
      justifyContent: 'flex-end',
      textAlign: 'end',
    },
    top: {
      alignItems: 'flex-start',
    },
    middle: {
      alignItems: 'center',
    },
    bottom: {
      alignItems: 'flex-end',
    },
    stretch: {
      alignItems: 'stretch',
    },
  };
  return aligns[align] || undefined;
};
// Distribution
// @group Grid System
// @param {string} dist [none] - 'around' and 'between'

export const rowDistribution = (dist) => {
  const dists = {
    around: {
      justifyContent: 'space-around',
    },

    between: {
      justifyContent: 'space-between',
    },
  };
  return dists[dist] || undefined;
};
