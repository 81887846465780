import PropTypes from 'prop-types';
import React from 'react';

export const TableHeader = ({ title, width }) => {
  return (
    <th width={width}>
      <div>{title}</div>
    </th>
  );
};

TableHeader.defaultProps = {
  width: null,
};

TableHeader.propTypes = {
  title: PropTypes.string.isRequired,
  width: PropTypes.number,
};
