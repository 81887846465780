import React from 'react';
import PropTypes from 'prop-types';

const RawHtml = ({ children, tag: Tag, ...props }) => (
  <Tag {...props} dangerouslySetInnerHTML={{ __html: children }} />
);

RawHtml.defaultProps = {
  children: null,
  tag: 'span',
};

RawHtml.propTypes = {
  children: PropTypes.string,
  tag: PropTypes.string,
};

export default RawHtml;
