import { colors, colorTheme } from '../../../styles/colors';
import { baseStyles, fieldBaseStyles } from '../../../styles/baseStyles';
import { base, bodyFont } from '../../../styles/typography';

const autoCompleteStyle = {
  fontFamily: base.fontFamily,
  marginBottom: 16,
  display: 'block',
  position: 'relative',
  '*': {
    boxSizing: 'border-box',
  },
  '.label': {
    ...fieldBaseStyles.label,
  },
  '.input-container': {
    '.input-icon': {
      top: 15,
      '.udt-icon': {
        width: 18,
        height: 18,
        path: {
          fill: colorTheme.hintDanger,
        },
      },
    },
  },
  '.search-box': {
    ...baseStyles.input,
    paddingRight: 40,
    transition: 'width 0.3s',
    '&:focus': {
      width: '100%',
      outline: 'none',
      borderColor: colors.cyan.dark,
    },
  },
  '.search-icon': {
    position: 'absolute',
    right: 16,
    top: 32,
  },
  '.options': {
    ...fieldBaseStyles.options,

    '.option-active': {
      background: 'whitesmoke',
    },
    '.no-options': {
      padding: '12px 16px',
      '.not-found': {
        ...bodyFont('md'),
        color: colorTheme.textDefault,
        display: 'block',
        marginBottom: 4,
      },
      '.no-results': {
        ...bodyFont('sm'),
        display: 'block',
        color: colors.gray.medium,
      },
    },
  },
};

export default autoCompleteStyle;
