export const renderObjectProperty = (item, property) => item[property];

export const normalizeRender = (render) => {
  /** Si es una string, es el string de la property del objeto* */
  if (typeof render === 'string') {
    return (item) => renderObjectProperty(item, render);
  }
  // Toma como que tiene que renderizar un string
  if (!render) {
    return (item) => item;
  }
  // asume que es una funcion
  return render;
};
