import { colors, colorTheme } from '../../styles/colors';
import { bodyFont, subtitleFont, titleFont } from '../../styles/typography';

export const getHeaderStyles = (t) => {
  const types = {
    simple: {
      '.title': { ...bodyFont('sm'), display: 'inline-block', margin: 0 },
    },
    list: {
      position: 'relative',
      padding: '16px 36px 16px 16px',
      borderTop: `1px solid ${colors.gray.lighten}`,
      borderBottom: 0,
      '.title': {
        ...titleFont('xs', 'sans'),
        color: colors.gray.darkest,
        display: 'inline-block',
        margin: 0,
      },
      '.chevron': {
        position: 'absolute',
        right: 16,
        top: '50%',
        marginTop: -10,
      },
    },
    menu: {
      padding: '16px 12px 16px 16px',
      borderBottom: `1px solid ${colors.gray.lighten}`,
      background: colors.gray.ultralight,
      '.title': {
        ...subtitleFont('md', 'serif'),
        color: colors.gray.darkest,
        display: 'inline-block',
        margin: 0,
      },
      '.chevron': { float: 'right' },
      '&.active': {
        borderLeft: `4px solid ${colorTheme.interactive}`,
        '.title': {
          color: colorTheme.interactive,
        },
      },
    },
    submenu: {
      padding: 0,
      '.title': {
        margin: '2px 0',
        ...bodyFont('sm'),
        color: colors.gray.darkest,
        display: 'inline-block',
        padding: '13px 12px 13px 24px',
      },
      '.chevron': {
        position: 'absolute',
        right: 12,
        top: '30%',
      },
      '.content': { borderBottom: `1px solid ${colors.gray.lighten}` },
      '&.active': {
        '.title': {
          color: colorTheme.interactive,
        },
      },
    },
  };
  return types[t];
};
export const getContentStyles = (t) => {
  const types = {
    simple: {},
    list: {},
    menu: {},
    submenu: {
      borderBottom: `1px solid ${colors.gray.lighten}`,
    },
  };
  return types[t];
};
export const headStyles = (type) => ({
  position: 'relative',
  display: 'block',
  width: '100%',
  textAlign: 'left',
  background: '#fff',
  border: 0,
  outline: 'none',
  '.title': {
    verticalAlign: 'middle',
    display: 'inline-block',
  },
  '&:hover .title': {
    color: colorTheme.interactive,
    transition: 'color 0.2s',
  },
  '.chevron-open': { transform: 'rotate(180deg)', transition: 'transform 0.2s' },
  '.chevron-close': { transform: 'rotate(0deg)', transition: 'transform 0.2s' },
  ...getHeaderStyles(type),
});
export const bodyStyles = (type, maxHeight) => ({
  maxHeight,
  overflow: 'hidden',
  transition: 'all 0.3s',
  ...getContentStyles(type),
});
