/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { colors } from '../../styles/colors';

const activeStyle = {
  backgroundColor: colors.cyan.lightest,
};

const styles = {
  border: `solid 1px ${colors.gray.light}`,
  borderRadius: 4,
  width: 'fit-content',
  height: 'fit-content',
  outline: 'none',

  '&:hover': { ...activeStyle },
  '&:focus': { ...activeStyle },
  '&.active': { ...activeStyle },
  '&.disabled': {
    backgroundColor: colors.gray.ultralight,
  },
};

const ButtonOption = ({ children, className, disabled, onClick }) => (
  <div
    css={styles}
    className={`${className} ${disabled ? 'disabled' : ''}`}
    onClick={onClick}
    onKeyDown={onClick}
    role="button"
    tabIndex="0"
  >
    {children}
  </div>
);

ButtonOption.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

ButtonOption.defaultProps = {
  className: '',
  disabled: true,
  onClick: () => {},
};

export default ButtonOption;
