import PropTypes from 'prop-types';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { baseBox } from './styles';

const styles = {
  ...baseBox,
  '.box .icon': { outline: 'none' },
};

export const Radio = ({
  checked,
  disabled,
  toggleCheck,
  children,
  checkComponent,
  className = '',
}) => {
  return (
    <div
      css={styles}
      className={`radio ${className}  ${disabled ? 'disabled' : ''} ${checked ? 'checked' : ''}`}
    >
      <div className="box">
        <div
          className="icon"
          onClick={toggleCheck}
          onKeyDown={toggleCheck}
          role="button"
          tabIndex="0"
        >
          {checkComponent}
        </div>
      </div>
      {children}
    </div>
  );
};

Radio.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  checkComponent: PropTypes.node,
  toggleCheck: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Radio.defaultProps = {
  checked: false,
  disabled: false,
  children: undefined,
  checkComponent: undefined,
  className: undefined,
};
