/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { colors, colorTheme } from '../../styles/colors';

const sliderThumbStyle = {
  borderRadius: 999,
  appearance: 'none',
  width: 16,
  height: 16,
  cursor: 'pointer',
  background: colors.cyan.dark,
};
const defaultSliderThumbStyle = {
  borderRadius: 999,
  appearance: 'none',
  width: 12,
  height: 12,
  cursor: 'pointer',
  border: `1px solid ${colorTheme.hintInput}`,
  background: colorTheme.hintGhostDisabled,
};

const slider = {
  position: 'absolute',
  appearance: 'none',
  border: 'none',
  width: '100%',
  height: 2,
  padding: 0,
  outline: 'none',
};

const styles = {
  paddingTop: 8,
  position: 'relative',
  '.ui-kit-slider': {
    ...slider,

    background: 'transparent',
    '&::-webkit-slider-thumb': {
      ...sliderThumbStyle,
    },
    '&::-moz-range-thumb': {
      ...sliderThumbStyle,
    },
  },
  '.ui-kit-slider-default': {
    ...slider,
    background: colorTheme.hintInput,

    '&::-webkit-slider-thumb': {
      ...defaultSliderThumbStyle,
    },
    '&::-moz-range-thumb': {
      ...defaultSliderThumbStyle,
    },
  },
};
const Slider = ({ min, max, defaultValue, value, step, onChange, onUpdate, className }) => {
  const onClickCaptured = (event) => {
    onChange(event.target.value);
  };

  const onInputValue = (event) => {
    onUpdate(event.target.value);
  };

  return (
    <div css={styles} className={className}>
      <input
        type="range"
        min={min}
        max={max}
        defaultValue={defaultValue}
        value={defaultValue}
        className="ui-kit-slider-default"
      ></input>
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        defaultValue={value}
        className="ui-kit-slider"
        onChange={onInputValue}
        onClickCapture={onClickCaptured}
        onTouchEnd={onClickCaptured}
      ></input>
    </div>
  );
};

Slider.defaultProps = {
  onChange: () => {},
  className: '',
  onUpdate: () => {},
};

Slider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  defaultValue: PropTypes.number,
  step: PropTypes.number,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default Slider;
