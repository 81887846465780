/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';

const Icon = ({ icon, color, size, className }) => {
  const styles = {
    display: 'inline-block',
    verticalAlign: 'middle',
    path: {
      fill: color,
    },
  };
  const iconStandardSizes = {
    xs: 12,
    sm: 16,
    md: 24,
    lg: 32,
  };
  const getSize = () => {
    let iconSize;
    if (Number.isInteger(Number.parseInt(size, 10))) {
      iconSize = size;
    } else {
      iconSize = iconStandardSizes[size] || iconStandardSizes.sm;
    }
    return iconSize;
  };
  return (
    <svg
      className={`udt-icon ${className}`}
      css={styles}
      width={`${getSize()}px`}
      height={`${getSize()}px`}
      viewBox="0 0 24 24"
    >
      <path fillRule="evenodd" clipRule="evenodd" d={icon} />
    </svg>
  );
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
  className: PropTypes.string,
};

Icon.defaultProps = {
  size: 16,
  color: '#777777',
  className: '',
};

export default Icon;
