/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { icons } from '../../Icons/icons';
import RawHtml from '../../../utils/components/RawHtml';
import { Loader } from '../../Loader/Loader';
import Input from '../Input/Input';
import style from './Autocomplete.style';
import { debounce } from './debounce';

const defaultFilter = (options, input) => {
  return options.filter((option) => option.toLowerCase().indexOf(input.toLowerCase()) > -1);
};

const defaultInputData = (option) => {
  return option;
};

export const paintSelected = (option, userInput) => {
  const match = option.match(new RegExp(userInput, 'i'));
  const content = option.replace(new RegExp(match, 'gi'), `<strong>${match}</strong>`);
  return <RawHtml>{content}</RawHtml>;
};

const defaultShowData = (option, userInput) => {
  return paintSelected(option, userInput);
};

const defaultNoResultsMessage = 'No se encontraron resultados para la busqueda';
const defaultNotFoundMessage = 'No encotramos el/la label';

export const Autocomplete = ({
  className,
  disabled,
  label,
  placeholder,
  options,
  filter,
  showData,
  inputData,
  onSelectedInput,
  min,
  debounceTime,
  noResultsMessage,
  onFocus,
  onBlur,
  error,
  info,
  value,
  notFoundMessage,
  required,
}) => {
  const [loading, setLoading] = useState(false);
  const [activeOption, setActiveOption] = useState(0);
  const [filteredOptions, setFilteredOptions] = useState(value ? filter(options, value) : []);
  const [showPredictible, setShowPredictible] = useState(false);
  const [userInput, setUserInput] = useState(value);

  const debouncedUpdate = (filteredOptions) => {
    debounce(() => {
      setFilteredOptions(filteredOptions);
      setShowPredictible(true);
      setLoading(false);
    }, debounceTime)();
  };

  const onChange = (event) => {
    const { value } = event.currentTarget;
    setUserInput(value);
    setActiveOption(0);

    if (value.length >= min) {
      setLoading(true);
      setShowPredictible(true);
      debouncedUpdate(filter(options, value));
    } else {
      setFilteredOptions([]);
      setLoading(false);
      setShowPredictible(false);
    }
  };

  const crossClickHandler = () => {
    setUserInput('');
    setShowPredictible(false);
  };

  const selectOption = (selectedOption) => {
    setUserInput(inputData(selectedOption));
    setShowPredictible(false);
    setFilteredOptions(filter(options, inputData(selectedOption)));

    if (onSelectedInput) {
      onSelectedInput(selectedOption);
    }
  };

  const onClick = (e, option, index) => {
    setActiveOption(index);
    selectOption(option);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      selectOption(filteredOptions[activeOption]);
    } else if (e.keyCode === 38) {
      if (activeOption === 0) {
        return;
      }
      setActiveOption(activeOption - 1);
    } else if (e.keyCode === 40) {
      if (activeOption === filteredOptions.length - 1) {
        return;
      }
      setActiveOption(activeOption + 1);
    }
  };

  const getPredictibleBox = () => {
    let predictibleBox = null;
    if (!showPredictible || !userInput) {
      return predictibleBox;
    }

    if (filteredOptions && filteredOptions.length) {
      predictibleBox = (
        <div className="options">
          {filteredOptions.map((option, index) => {
            const optionRender = showData(option, userInput);
            let className = 'option';
            if (index === activeOption) {
              className += ' option-active';
            }
            return (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <div
                className={className}
                key={index}
                onClick={(event) => onClick(event, option, index)}
                onKeyDown={(event) => onClick(event, option, index)}
                role="button"
                tabIndex="0"
              >
                {optionRender}
              </div>
            );
          })}
        </div>
      );
    } else if (loading) {
      predictibleBox = (
        <div className="options">
          <Loader />
        </div>
      );
    } else {
      predictibleBox = (
        <div className="options">
          <div className="no-options">
            <span className="not-found">
              {notFoundMessage} &quot;{userInput}&quot;
            </span>
            <span className="no-results">{noResultsMessage}</span>
          </div>
        </div>
      );
    }

    return predictibleBox;
  };

  const predictibleBox = getPredictibleBox();

  const handleFocus = (e) => {
    setShowPredictible(true);
    onFocus(e);
  };

  const handleBlur = (e) => {
    /**
     * polemico, el timeout es para que no se cierren las opciones antes de que capture el click
     * que la selecciona
     */
    setTimeout(() => setShowPredictible(false), 250);
    onBlur(e);
  };

  return (
    <React.Fragment>
      <div className={className} css={style}>
        <Input
          tag="input"
          label={label}
          value={userInput}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholder}
          className={className}
          error={error}
          info={info}
          disabled={disabled}
          icon={userInput.length > 0 ? icons.cross : null}
          onIconClick={crossClickHandler}
          onKeyDown={onKeyDown}
          required={required}
        >
          {predictibleBox}
        </Input>
      </div>
    </React.Fragment>
  );
};

Autocomplete.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  options: PropTypes.instanceOf(Array).isRequired,
  filter: PropTypes.func,
  showData: PropTypes.func,
  inputData: PropTypes.func,
  onSelectedInput: PropTypes.func,
  min: PropTypes.number,
  debounceTime: PropTypes.number,
  noResultsMessage: PropTypes.string,
  notFoundMessage: PropTypes.string,
  info: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
};

Autocomplete.defaultProps = {
  showData: defaultShowData,
  inputData: defaultInputData,
  filter: defaultFilter,
  min: 2,
  debounceTime: 350,
  onSelectedInput: undefined,
  noResultsMessage: defaultNoResultsMessage,
  notFoundMessage: defaultNotFoundMessage,
  label: '',
  value: '',
  placeholder: '',
  info: '',
  error: '',
  disabled: false,
  onFocus: () => {},
  onBlur: () => {},
  className: '',
  required: false,
};

export default Autocomplete;
