/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import PropTypes from 'prop-types';
import { makeMeRow } from '../../styles/grid';
import { respondTo } from '../../styles/respondTo';
import { desktopGutter, internalGutter, mobileGutter } from './constants';

const styles = {
  ...makeMeRow(mobileGutter),
  [respondTo('lg')]: {
    ...makeMeRow(desktopGutter),
    '&.internal': {
      ...makeMeRow(internalGutter),
    },
  },
  [respondTo('xl')]: {
    ...makeMeRow(desktopGutter),
    '&.internal': {
      ...makeMeRow(internalGutter),
    },
  },
};

const Row = ({ children, internal, className }) => {
  const childrenWithProps = internal
    ? React.Children.map(children, (child) => React.cloneElement(child, { internal }))
    : children;

  return (
    <div css={styles} className={`${internal ? 'internal' : ''} ${className}`}>
      {childrenWithProps}
    </div>
  );
};

Row.propTypes = {
  children: PropTypes.node.isRequired,
  internal: PropTypes.bool,
  className: PropTypes.string,
};

Row.defaultProps = {
  internal: false,
  className: '',
};

export default Row;
