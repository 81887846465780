import { colors, colorTheme } from '../../styles/colors';
import { base } from '../../styles/typography';

export const baseBox = {
  fontFamily: base.fontFamily,
  fontSize: 16,
  color: colors.gray.darkest,
  display: 'flex',
  alignItems: 'center',
  position: 'relative',

  '.box': {
    position: 'relative',
    width: 20,
    height: 20,
    backgroundColor: 'white',
    marginRight: 10,
  },

  '&.checked .box': {},
  '&.disabled .box': {
    backgroundColor: colorTheme.hintGhostDisabled,
  },
  '.icon': {
    borderRadius: 0.5,
    position: 'absolute',
    left: 0,
    top: 0,
    width: 20,
    height: 20,
    cursor: 'pointer',
    path: {
      fill: `${colorTheme.hintInteractive}`,
    },
  },
  '&.disabled .icon': {
    cursor: 'default',
    path: {
      fill: `${colorTheme.hintInput}`,
    },
  },
};
