/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';

import { colors } from '../../styles/colors';

const styles = {
  margin: '16px 0',
  border: 0,
  borderTop: `1px solid ${colors.gray.lighten}`,
};
const Divider = ({ className }) => {
  return <hr className={`udt-divider ${className}`} css={styles} />;
};

Divider.defaultProps = {
  className: undefined,
};

Divider.propTypes = {
  className: PropTypes.string,
};

export default Divider;
