/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { colors } from '../../styles/colors';
import { base } from '../../styles/typography';

const styles = {
  borderRadius: 4,
  fontSize: 10,
  lineHeight: 1,
  fontWeight: 'bold',
  fontFamily: base.fontFamily,
  padding: '5px 8px',
};

const variants = {
  danger: {
    color: colors.red.dark,
    backgroundColor: colors.red.lightest,
  },
  info: {
    color: colors.blue.dark,
    backgroundColor: colors.blue.lightest,
  },
  interactive: {
    color: colors.cyan.dark,
    backgroundColor: colors.cyan.lightest,
  },
  mute: {
    color: colors.gray.dark,
    backgroundColor: colors.gray.lightest,
  },
  success: {
    color: colors.green.dark,
    backgroundColor: colors.green.lightest,
  },
  warning: {
    color: colors.yellow.dark,
    backgroundColor: colors.yellow.lightest,
  },
};

const Label = ({ variant, className, children }) => (
  <span css={{ ...styles, ...variants[variant] }} className={className}>
    {children}
  </span>
);

Label.propTypes = {
  variant: PropTypes.oneOf(['info', 'success', 'warning', 'danger', 'mute']).isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Label.defaultProps = {
  className: '',
};

export default Label;
