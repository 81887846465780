/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { colors, colorTheme } from '../../../styles/colors';
import Icon from '../../Icons/Icon';
import { icons } from '../../Icons/icons';
import { useCheck } from '../useChecked';
import { Radio } from '../Radio';

const styles = {
  marginBottom: 16,
  '&.no-margin': {
    marginBottom: 0,
  },
  '.box': {
    borderRadius: 4,
    border: `1px solid ${colors.gray.light}`,
  },
  '.radio.checked .box': {
    backgroundColor: colorTheme.hintGhostHoverActive,
  },
  '.radio.checked.disabled .box': {
    backgroundColor: colorTheme.hintGhostDisabled,
  },
  '.span-label': {
    cursor: 'pointer',
  },
};
const Checkbox = ({
  checked: initialChecked,
  disabled,
  children,
  onChange,
  className,
  noMargin,
}) => {
  const { checked, toggleCheck } = useCheck(initialChecked, disabled, onChange);

  return (
    <div css={styles} className={`${className || ''} ${noMargin ? 'no-margin' : ''}`}>
      <Radio
        checked={checked}
        disabled={disabled}
        toggleCheck={toggleCheck}
        checkComponent={<div>{checked && <Icon size={20} icon={icons.check} />}</div>}
      >
        <span
          role="button"
          tabIndex="0"
          className="span-label"
          onClick={toggleCheck}
          onKeyDown={toggleCheck}
        >
          {children}
        </span>
      </Radio>
    </div>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  onChange: PropTypes.func,
  className: PropTypes.string,
  noMargin: PropTypes.bool,
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  children: undefined,
  className: undefined,
  onChange: () => {},
  noMargin: false,
};

export default Checkbox;
