/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { validatePropValue } from '../../utils/ErrorMsg';
import { colors as udtColors, colorTheme } from '../../styles/colors';

const Button = ({ variant, size, color, block, type, className, children, ...props }) => {
  const btnType =
    {
      submit: 'submit',
      reset: 'reset',
      button: 'button',
    }[type] || 'button';

  const getSize = (s) => {
    const sizes = {
      lg: {
        height: 56,
        lineHeight: '56px',
        fontSize: 20,
        padding: '0 24px',
      },
      md: {
        height: 48,
        lineHeight: '48px',
        fontSize: 16,
        padding: '0 20px',
      },
      sm: {
        height: 36,
        lineHeight: '36px',
        fontSize: 13,
        padding: '0 16px',
      },
    };
    validatePropValue(sizes[s], 'Button', 'size', s);
    return sizes[s] ? sizes[s] : undefined;
  };
  const getColorVariant = (c, v) => {
    const colors = {
      interactive: 'cyan',
      info: 'blue',
      danger: 'red',
      success: 'green',
    };
    const variants = {
      primary: {
        color: '#fff',
        backgroundColor: udtColors[colors[c]].dark,
        '&:hover': {
          backgroundColor: udtColors[colors[c]].medium,
        },
        '&.active': {
          backgroundColor: udtColors[colors[c]].medium,
        },
        '&:disabled': {
          color: '#fff',
          backgroundColor: colorTheme.hintFieldDisabled,
        },
      },
      secondary: {
        backgroundColor: '#fff',
        color: udtColors[colors[c]].dark,
        border: `1px solid ${udtColors[colors[c]].dark}`,

        '&:hover': {
          backgroundColor: udtColors[colors[c]].lightest,
        },
        '&.active': {
          backgroundColor: udtColors[colors[c]].lightest,
        },
        '&:disabled': {
          border: `1px solid ${udtColors.gray.lighten}`,
          backgroundColor: udtColors.gray.ultralight,
          color: colorTheme.hintFieldDisabled,
        },
      },
      link: {
        display: 'inline-block',
        lineHeight: '1em',
        background: 'transparent',
        border: 0,
        cursor: 'pointer',
        padding: 0,
        height: 'auto',
        outline: 'none',
        color: udtColors[colors[c]].dark,

        '&:hover': {
          color: udtColors[colors[c]].medium,
        },
        '&:disabled': {
          color: udtColors.gray.lighten,
        },
      },
    };
    validatePropValue(colors[c], 'Button', 'color', c);
    validatePropValue(variants[v], 'Button', 'variant', v);
    return variants[v] ? variants[v] : undefined;
  };

  const styles = [
    {
      borderRadius: 4,
      textDecoration: 'none',
      textAlign: 'center',
      cursor: 'pointer',
      border: 0,
      outline: 'none',
      position: 'relative',
      whiteSpace: 'nowrap',
      fontFamily: 'Nunito Sans, sans-serif',
      fontWeight: 700,
      transition: 'background-color 0.2s',
      '&:hover': {
        textDecoration: 'none',
      },
      '&:disabled': {
        cursor: 'default',
      },
    },
    getSize(size),
    getColorVariant(color, variant),
    { width: block ? '100%' : 'auto' },
  ];

  return (
    // eslint-disable-next-line react/button-has-type
    <button css={styles} {...props} className={className} type={btnType}>
      {children}
    </button>
  );
};

Button.defaultProps = {
  variant: 'primary',
  color: 'interactive',
  size: 'md',
  disabled: false,
  type: 'button',
  block: false,
  className: undefined,
};

Button.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  block: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
};

export default Button;
