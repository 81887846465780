/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../styles/colors';
import { subtitleFont } from '../../styles/typography';
import { baseLink } from '../../styles/baseStyles';
import { normalizeRender } from '../../utils/data/render';
import { TableHeader } from './TableHeader';

const border = `1px solid ${colors.gray.light}`;

const styles = {
  width: '100%',
  borderCollapse: 'collapse',
  border,
  backgroundColor: colors.gray.ultralight,
  '&.fixed-layout': {
    tableLayout: 'fixed',
  },
  'th, td': {
    textAlign: 'left',
    border,
  },

  th: {
    padding: '14px 20px 14px 8px',
    textTransform: 'uppercase',
    ...subtitleFont('sm', 'sans'),
    color: colors.gray.darkest,
    position: 'relative',
    '.icons': {
      position: 'absolute',
      right: 6,
      top: '25%',
      height: 20,
      display: 'flex',
      alignItems: 'center',
      svg: {
        display: 'block',
        cursor: 'pointer',
      },
    },
  },

  td: {
    padding: '10px 8px',
    ...subtitleFont('md', 'sans'),
    fontWeight: 'normal',
    color: colors.gray.darkest,
    backgroundColor: 'white',
    '&:first-of-type': {
      a: {
        ...baseLink,
      },
    },
  },

  tr: {
    '&:hover': {
      backgroundColor: colors.cyan.lightest,
    },
  },

  '&.transparent-column': {
    border: 0,
    'thead th:last-child': {
      backgroundColor: 'white',
      border: 0,
      '.icons': {
        display: 'none',
      },
    },
    'tbody td:last-child': {
      backgroundColor: 'white',
      border: 0,
    },
  },
};

/**
 * @param headers
 * @param rows
 * @param className
 * @param onOrderClick
 * @param fixedLayout
 * @returns {*}
 * @constructor
 */
export const Table = ({
  headers,
  rows,
  className,
  onOrderClick,
  fixedLayout,
  isTransparentColumn,
  fallback,
}) => {
  const normalizedHeaders = headers.map((h) => ({ ...h, render: normalizeRender(h.render) }));

  return (
    <Fragment>
      <table
        css={styles}
        className={`${isTransparentColumn ? 'transparent-column' : ''} ${
          fixedLayout ? 'fixed-layout' : ''
        } ${className}`}
      >
        <thead>
          <tr>
            {headers.map((h) => (
              <TableHeader
                width={h.width}
                title={h.title}
                id={h.id}
                onOrderClick={onOrderClick}
                filter={h.filter}
                activeFilterIcon={h.activeFilterIcon}
                key={h.id}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => {
            return (
              <tr key={i}>
                {normalizedHeaders.map((h, hi) => (
                  <td key={hi}>{h.render(row)}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {rows.length === 0 && fallback}
    </Fragment>
  );
};

Table.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      render: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
      filter: PropTypes.element,
      activeFilterIcon: PropTypes.bool,
      width: PropTypes.number,
    }),
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
  onOrderClick: PropTypes.func,
  fixedLayout: PropTypes.bool,
  isTransparentColumn: PropTypes.bool,
  fallback: PropTypes.element,
};

Table.defaultProps = {
  onOrderClick: undefined,
  className: '',
  isTransparentColumn: false,
  fixedLayout: true,
  fallback: null,
};
