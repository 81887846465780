/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { colors } from '../../styles/colors';
import { bodyFont } from '../../styles/typography';
import { validatePropValue } from '../../utils/ErrorMsg';
import Icon from '../Icons/Icon';
import { icons } from '../Icons/icons';

const Alert = ({
  type,
  autoDismiss,
  autoDismissDelay,
  dismissible,
  text,
  className,
  children,
  maxWidth,
  onClose,
}) => {
  const [close, setClose] = useState(false);

  useEffect(() => {
    if (autoDismiss) {
      const timer = setTimeout(() => {
        setClose(true);
        onClose();
      }, autoDismissDelay);

      return () => clearTimeout(timer);
    }
  }, [autoDismiss, autoDismissDelay, onClose]);

  const types = {
    info: 'blue',
    success: 'green',
    warning: 'yellow',
    error: 'red',
  };

  validatePropValue(types[type], 'Alert', 'type', type);
  validatePropValue(text, 'Alert', 'text', text);

  const selectedType = types[type] || 'blue';
  const styles = {
    fontFamily: '"Nunito Sans", sans-serif',
    padding: 16,
    border: '1px solid',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: colors[selectedType].lightest,
    borderColor: colors[selectedType].dark,
    color: colors[selectedType].dark,
    maxWidth,
    width: '100%',
    boxSizing: 'border-box',
    overflow: 'hidden',
    '.text-container': {
      float: 'left',
      width: '100%',
    },
    '.text': {
      float: 'left',
      width: dismissible ? 'calc(100% - 24px)' : '100%',
      display: 'inline-block',
      margin: 0,
      ...bodyFont('md'),
      color: colors[selectedType].dark,
    },
    '.close-btn': {
      float: 'left',
      cursor: 'pointer',
      border: 0,
      background: 'transparent',
      padding: 0,
    },

    '.extra-content': {
      paddingTop: 16,
      button: {
        backgroundColor: colors[selectedType].dark,
        color: '#fff',
        padding: 16,
        fontSize: 16,
        marginTop: 16,
        borderRadius: 4,
        border: 'none',
        cursor: 'pointer',
      },
      'button:hover': {
        backgroundColor: colors[selectedType].medium,
      },
    },
  };

  return (
    !close && (
      <div css={styles} className={className}>
        <div className="text-container">
          <div className="text">{text}</div>
          {dismissible && (
            <button
              className="close-btn"
              type="button"
              title="Close"
              onClick={() => {
                setClose(true);
                onClose();
              }}
            >
              <Icon icon={icons.close} color={colors[selectedType].dark} size={16} />
            </button>
          )}
        </div>
        {children && <div className="extra-content">{children}</div>}
      </div>
    )
  );
};

Alert.defaultProps = {
  dismissible: false,
  className: undefined,
  children: undefined,
  maxWidth: '100%',
  type: 'info',
  autoDismiss: false,
  autoDismissDelay: 3000,
  onClose: () => {},
};

Alert.propTypes = {
  type: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  dismissible: PropTypes.bool,
  maxWidth: PropTypes.string,
  className: PropTypes.string,
  autoDismiss: PropTypes.bool,
  autoDismissDelay: PropTypes.number,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
};

export default Alert;
