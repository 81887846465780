import PropTypes from 'prop-types';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Link } from 'react-router-dom';
import { respondTo } from '../../styles/respondTo';

const styles = {
  margin: '0 auto',
  a: {
    verticalAlign: 'initial',
    height: 32,
    [respondTo('md')]: {
      height: 44,
    },
    img: {
      '&.logo-123': {
        verticalAlign: 'initial',
      },
    },
  },
};

export const DefaultHeaderChildren = ({ className = '' }) => {
  return (
    <div css={styles} className={className}>
      <Link to="/">
        <img
          className="logo-123"
          src="https://branding.123seguro.com/logotypes/123/color/logo_123seguro.svg"
          alt="123Seguro"
          title="123Seguro"
        />
      </Link>
    </div>
  );
};

DefaultHeaderChildren.propTypes = {
  className: PropTypes.string,
};

DefaultHeaderChildren.defaultProps = {
  className: '',
};
