import { colors } from './colors';
import { respondTo } from './respondTo';

export const boxShadowed = {
  boxShadow: `0px 0px 15px ${colors.gray.lighten}`,
  borderRadius: 8,
  backgroundColor: '#FFF',
  padding: 16,
  [respondTo('md')]: {
    padding: 24,
  },
  [respondTo('lg')]: {
    padding: 32,
  },
};
