/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import Button from '../../Button/Button';
import { range } from '../../../utils/data/range';
import { getMonthRangeLimits } from './date-range-limits';

const Styles = {
  '.grid-years': {
    gridTemplateColumns: 'repeat(4, 75px)',
    gridTemplateRows: 'repeat(3, 72px)',
    display: 'grid',
    margin: 0,
    padding: '0 14px 0 14px',
    li: {
      listStyle: 'none',
      width: 60,
      height: 52,
      textAlign: 'center',
      paddingTop: 18,
    },
  },
};

const getMonths = (year, limits, currentDate) => {
  const [monthsStart, monthsEnd, monthsLast] = getMonthRangeLimits(year, limits, currentDate);

  const monthBeforeValid = range(0, monthsStart);
  const validMonths = range(monthsStart, monthsEnd);
  const monthsAfterValid = range(monthsEnd, monthsLast);

  return [monthBeforeValid, validMonths, monthsAfterValid];
};

const InvalidMonthListButton = ({ month }) => (
  <li>
    <Button variant="link" size="sm" disabled={true}>
      {month + 1}
    </Button>
  </li>
);

const ValidMonthListButton = ({ month, onClick }) => (
  <li>
    <Button variant="link" size="sm" onClick={onClick}>
      {month + 1}
    </Button>
  </li>
);

const MonthSelect = ({ selectMonth, year, limits, currentDate }) => {
  const [monthBeforeValid, validMonths, monthsAfterValid] = getMonths(year, limits, currentDate);

  return (
    <div css={Styles}>
      <ul className="grid-years">
        {monthBeforeValid.map((month) => (
          <InvalidMonthListButton key={month} month={month} />
        ))}

        {validMonths.map((month) => (
          <ValidMonthListButton key={month} onClick={() => selectMonth(month)} month={month} />
        ))}

        {monthsAfterValid.map((month) => (
          <InvalidMonthListButton key={month} month={month} />
        ))}
      </ul>
    </div>
  );
};

MonthSelect.propTypes = {
  selectMonth: PropTypes.func.isRequired,
  year: PropTypes.number.isRequired,
  limits: PropTypes.object.isRequired,
  currentDate: PropTypes.instanceOf(Date).isRequired,
};

InvalidMonthListButton.propTypes = {
  month: PropTypes.number.isRequired,
};
ValidMonthListButton.propTypes = {
  month: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default MonthSelect;
