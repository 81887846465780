import { colorTheme } from './colors';
import { respondTo } from './respondTo';
import '../fonts/geometria_regular_macroman/stylesheet.css';
import '../fonts/geometria_medium_macroman/stylesheet.css';
import '../fonts/geometria_extrabold_macroman/stylesheet.css';

const variants = {
  serif: {
    fontFamily: 'Zilla Slab, serif',
    fontWeight: 600,
  },
  brand: {
    fontFamily: 'geometria-regular',
    fontWeight: 'normal',
  },
  brand_medium: {
    fontFamily: 'geometria-medium',
    fontWeight: 'normal',
  },
  brand_extrabold: {
    fontFamily: 'geometria-extrabold',
    fontWeight: 'normal',
  },
};

export const base = {
  fontFamily: 'Nunito Sans, sans-serif',
  fontWeight: 400,
  lineHeight: '1.2em',
  color: colorTheme.textDefault,
};

export const titleFont = (size = 'lg', variant = 'sans') => {
  if (size === 'xl' && ['sans', 'serif'].includes(variant)) {
    size = 'lg';
  }
  const baseTitle = {
    fontWeight: 700,
    lineHeight: '1.1em',
  };

  const sizes = {
    xl: { fontSize: 40 },
    lg: { fontSize: 32 },
    md: { fontSize: 24 },
    sm: { fontSize: 20 },
    xs: { fontSize: 16 },
  };

  const sizesDesktop = {
    xl: { fontSize: 60 },
    lg: { fontSize: 48 },
    md: { fontSize: 36 },
    sm: { fontSize: 28 },
    xs: { fontSize: 20 },
  };

  return {
    ...base,
    ...baseTitle,
    ...sizes[size],
    [respondTo('md')]: {
      ...sizesDesktop[size],
    },
    ...variants[variant],
  };
};
export const subtitleFont = (size = 'md', variant = 'sans') => {
  const baseSubTitle = {
    fontWeight: 600,
    lineHeight: '1.1em',
  };

  const sizes = {
    lg: { fontSize: 22 },
    md: { fontSize: 16 },
    sm: { fontSize: 12 },
    xs: { fontSize: 10 },
  };

  return { ...base, ...baseSubTitle, ...sizes[size], ...variants[variant] };
};

export const bodyFont = (size = 'md', variant = 'sans') => {
  if (size === 'xl' && ['sans', 'serif'].includes(variant)) {
    size = 'lg';
  }
  const baseBody = {
    fontWeight: 400,
  };

  const sizes = {
    xl: { fontSize: 24 },
    lg: { fontSize: 18 },
    md: { fontSize: 16 },
    sm: { fontSize: 13 },
    xs: { fontSize: 10 },
  };

  return { ...base, ...baseBody, ...sizes[size], ...variants[variant] };
};
