import { colors } from '../../styles/colors';

const borderRadius = 4;

export const buttonSelectorListStyles = {
  '.ui-button-selector': {
    borderBottom: 0,
    '&:first-of-type': {
      borderTopLeftRadius: borderRadius,
      borderTopRightRadius: borderRadius,
    },

    '&:last-of-type': {
      borderBottom: `1px solid ${colors.gray.light}`,
      borderBottomLeftRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
    },
  },
};
