import { useEffect, useState } from 'react';

export const useCheck = (initialChecked, disabled, onChange) => {
  const [checked, setChecked] = useState(initialChecked);

  useEffect(() => {
    setChecked(initialChecked);
  }, [initialChecked]);

  const toggleCheck = () => {
    if (!disabled) {
      onChange(!checked);
      setChecked(!checked);
    }
  };

  return { checked, setChecked, toggleCheck };
};
