/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Checkbox from '../Radios/Checkbox/Checkbox';
import Icon from '../Icons/Icon';
import { icons } from '../Icons/icons';
import { colors, colorTheme } from '../../styles/colors';
import { bodyFont } from '../../styles/typography';
import { fieldBaseStyles } from '../../styles/baseStyles';

const styles = {
  position: 'relative',
  '.multiple-options': {
    padding: '16px 16px 0px 16px',
    border: `1px solid ${colors.gray.lighten}`,
    borderRadius: 4,
    '.option': {
      span: {
        ...bodyFont('md', 'sans'),
      },
    },
  },
  '.label': {
    ...fieldBaseStyles.label,
    marginBottom: 16,
    '.required': {
      color: colors.red.medium,
      position: 'absolute',
      left: '-0.633em',
    },
  },
  '.error-message': {
    color: colorTheme.hintDanger,
    fontSize: 13,
    marginTop: 4,
  },
  '.error-checkbox .box': {
    borderColor: colorTheme.hintDanger,
  },
};

export const MultipleOptions = ({
  items,
  onChange,
  className,
  noBorder,
  label,
  required,
  error,
  selectAll,
}) => {
  const [itemsSelected, setItemsSelected] = useState(items.filter((item) => item.selected));

  const onClick = (item) => {
    if (selectAll.enable && selectAll.value === item.value) {
      setItemsSelected(items);
      onChange(items);
      return;
    }
    const index = itemsSelected.findIndex((i) => i.value === item.value);
    const newItems = [...itemsSelected];
    if (index >= 0) {
      newItems.splice(index, 1);
      const indexSelectAll = newItems.findIndex((i) => i.value === selectAll.value);
      if (indexSelectAll > 0) {
        newItems.splice(indexSelectAll, 1);
      }
    } else {
      newItems.push(item);
    }
    setItemsSelected(newItems);
    onChange(newItems);
  };

  return (
    <div css={styles} className={className}>
      {label && (
        <label className="label">
          {required && <span className="required">*</span>} {label}
        </label>
      )}

      <div
        className="multiple-options"
        style={
          noBorder
            ? {
                border: 'unset',
                padding: 'unset',
              }
            : {}
        }
      >
        {items.map((item, i) => (
          <Checkbox
            key={i}
            className={`option ${error ? 'error-checkbox' : ''}`}
            onChange={() => onClick(item)}
            checked={item.selected}
            disabled={item.disabled}
          >
            {item.displayName}
          </Checkbox>
        ))}
      </div>

      {error && (
        <span className="error-message">
          <Icon icon={icons.error} color={colorTheme.hintDanger} />
          <span>{error}</span>
        </span>
      )}
    </div>
  );
};

MultipleOptions.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  selectAll: PropTypes.shape({
    enable: PropTypes.bool,
    value: PropTypes.bool,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
      displayName: PropTypes.string,
      selected: PropTypes.bool,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  noBorder: PropTypes.bool,
  error: PropTypes.string,
};

MultipleOptions.defaultProps = {
  className: '',
  label: '',
  required: false,
  noBorder: false,
  error: '',
  selectAll: {
    enable: false,
  },
};

export default MultipleOptions;
