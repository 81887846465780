/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { makeMeColumn } from '../../styles/grid';
import { respondTo } from '../../styles/respondTo';
import { mobileGutter, desktopGutter, internalGutter } from './constants';

const createGrid = (className, columnTotal, columnGutter) => {
  const grid = {};
  for (let i = 1; i <= columnTotal; i += 1) {
    grid[`${className + i}`] = {
      ...makeMeColumn(i, columnTotal, columnGutter),
    };
  }
  return grid;
};

const mobileTotal = 12;
const desktopTotal = 12;

const styles = {
  ...createGrid('&.col-', mobileTotal, mobileGutter),
  [respondTo('lg')]: {
    ...createGrid('&.col-desk-', desktopTotal, desktopGutter),
    '&.internal': {
      ...createGrid('&.col-desk-', desktopTotal, internalGutter),
    },
  },
  [respondTo('xl')]: {
    ...createGrid('&.col-desk-', desktopTotal, desktopGutter),
    '&.internal': {
      ...createGrid('&.col-desk-', desktopTotal, internalGutter),
    },
  },
};

const Column = ({ children, mobile, desktop, internal, className }) => (
  <div
    css={styles}
    className={`col-${mobile} col-desk-${desktop} ${className} ${internal ? 'internal' : ''}`}
  >
    {children}
  </div>
);

Column.propTypes = {
  children: PropTypes.node.isRequired,
  mobile: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  desktop: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  className: PropTypes.string,
  internal: PropTypes.bool,
};

Column.defaultProps = {
  mobile: 12,
  desktop: 12,
  className: '',
  internal: false,
};

export default Column;
