import { colors as udtColors, colors, colorTheme } from './colors';
import { bodyFont, subtitleFont, titleFont } from './typography';

export const baseLink = {
  color: colors.cyan.dark,
  textDecoration: 'none',
  '&:hover': {
    color: colors.cyan.medium,
    textDecoration: 'underline',
  },
  '&:visited': {
    color: colors.cyan.dark,
  },
};

export const baseStyles = {
  '*::-webkit-scrollbar': { width: '6px', height: '6px' },
  '*::-webkit-scrollbar-thumb': { background: udtColors.gray.dark, borderRadius: '3px' },
  '*::-webkit-scrollbar-thumb:hover': { background: udtColors.gray.darken },

  body: {
    backgroundColor: '#fff',
    fontDisplay: 'swap',
    textRendering: 'optimizeLegibility !important',
    WebkitFontSmoothing: 'antialiased',
    boxSizing: 'border-box',
    lineHeight: '1.2em',
    color: colors.gray.dark,
    ...bodyFont('md'),
  },
  '*,*::before,*::after': {
    boxSizing: 'inherit',
    // fontFamily: 'sans-serif',
  },
  'a,abbr,acronym,b,bdo,big,br,cite,code,dfn,em,i,img,input,kbd,label,q,samp,select,small,span,strong,sub,sup,textarea,tt,var,button,del,ins,map,object':
    {
      display: 'inline-block',
    },
  'h1,h2,h3,h4,h5,h6': {
    margin: '0 0 1em 0',
  },
  h1: titleFont('lg'),
  h2: titleFont('md'),
  h3: titleFont('sm'),
  h4: titleFont('xs'),
  h5: subtitleFont('md'),
  h6: subtitleFont('sm'),
  p: {
    margin: '0 0 1em 0',
  },
  strong: {
    fontWeight: 700,
    display: 'inline',
  },
  em: {
    fontStyle: 'italic',
  },
  a: baseLink,
  button: {
    cursor: 'pointer',
  },
  input: {
    width: '100%',
    outline: 'none',
    ...bodyFont('md'),
    color: colorTheme.textDefault,
    border: `1px solid ${colors.gray.light}`,
    borderRadius: 4,
    padding: '14px 16px',
    '&::placeholder': {
      ...bodyFont('md'),
      color: colorTheme.textPlaceholder,
    },
    '&:disabled': {
      color: colors.gray.dark,
      backgroundColor: colorTheme.hintFieldDisabled,
    },
    '&:focus': {
      borderColor: colors.cyan.dark,
    },
  },
  textarea: {
    '&::placeholder': {
      ...bodyFont('md'),
      color: colorTheme.textPlaceholder,
    },
  },
  'button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring':
    {
      outline: 'none',
    },
};

export const fieldBaseStyles = {
  label: {
    display: 'block',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1,
    marginBottom: 4,
    color: colors.gray.dark,
  },
  options: {
    border: `solid 1px ${colors.gray.light}`,
    borderRadius: 4,
    cursor: 'pointer',
    width: 'inherit',
    backgroundColor: 'white',
    color: colors.gray.darkest,
    borderColor: udtColors.cyan.dark,
    paddingTop: 4,
    paddingBottom: 4,
    marginTop: -1,
    zIndex: 1,
    maxHeight: 152,
    overflow: 'auto',
    div: {
      width: baseStyles.width,
    },
    '.option': {
      width: baseStyles.width,
      padding: '8px 15px',
      outline: 'none',
      '&:hover': {
        backgroundColor: udtColors.cyan.lightest,
        transitionDuration: '0.2s',
      },
      '&:first-of-type': {
        borderTopLeftRadius: baseStyles.borderRadius,
        borderTopRightRadius: baseStyles.borderRadius,
      },
      '&:last-of-type': {
        borderBottomLeftRadius: baseStyles.borderRadius,
        borderBottomRightRadius: baseStyles.borderRadius,
      },
    },
  },
};
