import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { colors } from '../../styles/colors';
import { subtitleFont } from '../../styles/typography';

const normalColor = colors.gray.darkest;
const activeColor = colors.cyan.dark;

const style = {
  position: 'relative',
  padding: '15px 40px 15px 15px',
  color: normalColor,
  backgroundColor: colors.gray.ultralight,
  borderBottom: `1px solid ${colors.gray.lighten}`,

  '&.active': {
    borderLeft: `4px solid ${activeColor}`,
    color: activeColor,

    '.title': {
      color: activeColor,
    },
  },

  a: {
    color: 'inherit',
    textDecoration: 'none',
  },

  '.title': {
    ...subtitleFont('md', 'serif'),
    color: normalColor,

    '&:hover': {
      color: activeColor,
      transitionDuration: '0.25s',
    },
  },
};

export const MenuTab = ({ title, path, closeMenu, active = false, className = '' }) => {
  return (
    <div css={style} className={active ? 'active' : ''}>
      <Link onClick={closeMenu} to={path} className={className}>
        <span className="title">{title}</span>
      </Link>
    </div>
  );
};

MenuTab.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  className: PropTypes.string,
  closeMenu: PropTypes.func,
  active: PropTypes.bool,
};

MenuTab.defaultProps = {
  className: '',
  active: false,
  closeMenu: () => {},
};
