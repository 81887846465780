import PropTypes from 'prop-types';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { colors } from '../../styles/colors';

const getSizeStyles = (size) => {
  const sizes = {
    xs: {
      dim: 16,
      lineWidth: 3,
      margin: '0px',
    },
    sm: {
      dim: 24,
      lineWidth: 4,
      margin: '0px',
    },
    md: {
      dim: 32,
      lineWidth: 4,
      margin: '16px auto',
    },
    lg: {
      dim: 58,
      lineWidth: 6,
      margin: '32px auto',
    },
  };

  const selectedSize = sizes[size] ?? sizes['md'];

  const lineWidth = selectedSize.lineWidth;
  const lightBorder = `${lineWidth}px solid  #D8D8D8`;
  const strongBorder = `${lineWidth}px solid ${colors.gray.light}`;

  return { ...selectedSize, lightBorder, strongBorder };
};

const getStyles = (size) => {
  const { dim, margin, lightBorder, strongBorder } = getSizeStyles(size);

  return {
    margin: margin,
    fontSize: 10,
    position: 'relative',
    textIndent: '-9999em',
    borderTop: strongBorder,
    borderRight: strongBorder,
    borderBottom: strongBorder,
    borderLeft: lightBorder,
    WebkitTransform: 'translateZ(0)',
    msTransform: 'translateZ(0)',
    transform: 'translateZ(0)',
    WebkitAnimation: 'load8 1.1s infinite linear',
    animation: 'load8 1.1s infinite linear',
    height: dim,
    width: dim,
    borderRadius: '50%',

    '@-webkit-keyframes load8': {
      '0%': {
        WebkitTransform: ' rotate(0deg)',
        transform: ' rotate(0deg)',
      },
      '100%': {
        WebkitTransform: 'rotate(360deg)',
        transform: 'rotate(360deg)',
      },
    },
    '@keyframes load8': {
      '0%': {
        WebkitTransform: ' rotate(0deg)',
        transform: ' rotate(0deg)',
      },
      '100%': {
        WebkitTransform: 'rotate(360deg)',
        transform: 'rotate(360deg)',
      },
    },
  };
};

export const Loader = ({ className, size }) => {
  return <div css={getStyles(size)} className={className} />;
};

Loader.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
};

Loader.defaultProps = {
  className: '',
  size: 'md',
};
