import { getDaysInMonth, addDays } from 'date-fns';

const addDay = (currentDate) => addDays(currentDate, 1);

const getStartYear = (ageLimits, currentDate) => addDay(currentDate).getFullYear() - ageLimits.max;
const getEndYear = (ageLimits, currentDate) => currentDate.getFullYear() - ageLimits.min;

export const getDayRangeLimits = (year, month, ageLimits, currentDate) => {
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();

  const tomorrowsDate = addDay(currentDate).getDate();
  const tomorrowsMonth = addDay(currentDate).getMonth();

  const firstValidDay =
    year === getStartYear(ageLimits, currentDate) && month === tomorrowsMonth ? tomorrowsDate : 1;

  const lastDayOfMonth = getDaysInMonth(new Date(year, month, 1)) + 1;
  const lastValidDay =
    year === getEndYear(ageLimits, currentDate) && month === currentMonth
      ? currentDay + 1
      : lastDayOfMonth;

  return [firstValidDay, lastValidDay, lastDayOfMonth];
};

export const getMonthRangeLimits = (year, ageLimits, currentDate) => {
  const tomorrow = addDay(currentDate);

  const isStartYear = year === getStartYear(ageLimits, currentDate);
  const firstValidMonth = isStartYear ? tomorrow.getMonth() : 0;

  const isEndYear = year === getEndYear(ageLimits, currentDate);
  const lastValidMonth = isEndYear ? currentDate.getMonth() + 1 : 12;

  const lastMonth = 12;

  return [firstValidMonth, lastValidMonth, lastMonth];
};

export const getYearRangeLimits = (ageLimits, currentDate) => {
  const firstValidYear = getStartYear(ageLimits, currentDate);
  const lastValidYear = getEndYear(ageLimits, currentDate) + 1;

  return [firstValidYear, lastValidYear];
};
