/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { colors } from '../../styles/colors';

const styles = {
  boxSizing: 'border-box',
  display: 'inline-block',
  textAlign: 'center',
  listStyle: 'none',
  padding: '10px 7px',
  height: 40,
  fontSize: 16,
  color: colors.cyan.dark,
  borderRight: `1px solid ${colors.gray.lighten}`,
  cursor: 'pointer',
  ':first-of-type': {
    borderLeft: `1px solid ${colors.gray.lighten}`,
  },
};

const Tab = ({ tabKey, label, onClick, activeTab }, ref) => {
  let className = 'tab-list-item';
  let finalStyle = styles;
  if (activeTab) {
    className += ' tab-list-active';
    finalStyle = {
      ...styles,
      borderBottom: `4px solid ${colors.cyan.dark}`,
      outline: 'none',
    };
  }

  return (
    <li
      ref={ref}
      css={finalStyle}
      onClick={(e) => onClick(tabKey ? tabKey : label, e)}
      onKeyDown={(e) => onClick(tabKey ? tabKey : label, e)}
      role="tab"
      tabIndex="0"
      className={className}
    >
      {label}
    </li>
  );
};

Tab.defaultProps = {
  tabKey: '',
  activeTab: false,
};

Tab.propTypes = {
  tabKey: PropTypes.string,
  activeTab: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default forwardRef((props, ref) => Tab(props, ref));
