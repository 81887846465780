const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};
export const respondTo = (minMedia, maxMedia) => {
  let query = '@media only screen';
  if (minMedia) {
    query += ` and (min-width: ${breakpoints[minMedia]}px)`;
  }
  if (maxMedia) {
    query += ` and (max-width: ${breakpoints[maxMedia]}px)`;
  }
  return query;
};
