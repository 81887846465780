/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { colors, colorTheme } from '../../styles/colors';
import { bodyFont } from '../../styles/typography';
import Input from '../Fields/Input/Input';
import Modal from '../Modal/Modal';
import { icons } from '../Icons/icons';
import Icon from '../Icons/Icon';
import { ButtonWrapper } from '../ButtonWrapper/ButtonWrapper';
import YearsSelect from './Components/YearsSelect';
import MonthSelect from './Components/MonthSelect';
import DaySelected from './Components/DaySelected';

const styles = {
  '.modal-date': {
    '.modal-date-title': {
      background: colors.blue.lightest,
      color: colors.blue.dark,
      fontSize: bodyFont('md'),
      textAlign: 'center',
      margin: 0,
      padding: '8px 0',
      '.back-button': {
        paddingRight: '2%',
        position: 'absolute',
        display: 'block',
        marginLeft: 12,
      },
    },
    '.modal-date-content': {},
    '.modal-header': {
      padding: '0 24px 20px 24px',
      border: 'initial',
      margin: 0,
    },
    '.modal-content': {
      padding: 0,
      paddingTop: 24,
      width: 328,
    },
  },
};

const validDateOrToday = (date) => {
  return isNaN(new Date(date)) ? new Date() : new Date(date);
};

const DatePickerBySteps = (props) => {
  const {
    className,
    texts,
    selectedDate,
    error,
    defaultValue,
    onDirty,
    limits,
    placeholder,
    textValue,
    actualDate,
  } = props;
  const [show, setShow] = useState(false);
  const [yearSelected, setYearSelected] = useState(1986);
  const [monthSelected, setMonthSelected] = useState(1);
  const [daySelected, setDaySelected] = useState(2);
  const [dateSelected, setDateSelected] = useState(defaultValue);
  const [step, setStep] = useState('years');
  const [title, setTitle] = useState(texts.selectingYear);
  const [dirty, setDirty] = useState(false);
  const currentDate = validDateOrToday(actualDate);

  const showModal = () => {
    setStep('years');
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };
  const selectYear = (year) => {
    setYearSelected(year);
    setStep('months');
  };

  const selectMonth = (month) => {
    setMonthSelected(month);
    setStep('days');
  };

  const selectDay = (day) => {
    setDaySelected(day);
    selectedDate(dateSelected);
    onDirty();
    setDirty(true);
    hideModal();
  };

  const previusStep = () => {
    switch (step) {
      case 'months': {
        setStep('years');
        break;
      }
      case 'days': {
        setStep('months');
        break;
      }
      default:
        break;
    }
  };

  const getTextInput = () => {
    return textValue
      ? textValue
      : dateSelected
      ? format(new Date(dateSelected), 'dd/MM/yyyy', { locale: es })
      : '';
  };

  useEffect(() => {
    if (dirty) {
      const date = new Date(yearSelected, monthSelected, daySelected);
      setDateSelected(date);
    }
  }, [yearSelected, monthSelected, daySelected, dirty]);

  useEffect(() => {
    if (dirty) {
      selectedDate(dateSelected);
    }
  }, [dateSelected, selectedDate, dirty]);

  useEffect(() => {
    switch (step) {
      case 'years': {
        setTitle(texts.selectingYear);
        break;
      }
      case 'months': {
        setTitle(texts.selectingMonth);
        break;
      }
      case 'days': {
        setTitle(texts.selectingDay);
        break;
      }
      default:
        break;
    }
  }, [step, texts]);

  return (
    <div css={styles} className={className}>
      <Input
        value={getTextInput()}
        label={''}
        onClick={showModal}
        error={error}
        readOnly={true}
        placeholder={placeholder}
      />
      <Modal show={show} onClose={hideModal} title="" className="modal-date">
        <p className="modal-date-title">
          {(step === 'months' || step === 'days') && (
            <ButtonWrapper onClick={previusStep} className="back-button">
              <Icon icon={icons.back} size={16} color={colorTheme.hintInteractive} />
            </ButtonWrapper>
          )}
          {title}
        </p>
        <div className="modal-date-content">
          {step === 'years' && (
            <YearsSelect selectYear={selectYear} limits={limits} currentDate={currentDate} />
          )}
          {step === 'months' && (
            <MonthSelect
              year={yearSelected}
              selectMonth={selectMonth}
              limits={limits}
              currentDate={currentDate}
            />
          )}
          {step === 'days' && (
            <DaySelected
              selectDay={selectDay}
              year={yearSelected}
              month={monthSelected}
              limits={limits}
              currentDate={currentDate}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

DatePickerBySteps.propTypes = {
  selectedDate: PropTypes.func.isRequired,
  onDirty: PropTypes.func,
  className: PropTypes.string,
  texts: PropTypes.object,
  error: PropTypes.string,
  defaultValue: PropTypes.instanceOf(Date),
  limits: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  placeholder: PropTypes.string,
  textValue: PropTypes.string,
  actualDate: PropTypes.instanceOf(Date),
};

DatePickerBySteps.defaultProps = {
  error: null,
  className: '',
  placeholder: '',
  onDirty: () => {},
  defaultValue: null,
  texts: {
    selectingYear: 'Año de nacimiento',
    selectingMonth: 'Mes de nacimiento',
    selectingDay: 'Día de nacimiento',
  },
  limits: {
    min: 18,
    max: 90,
  },
  textValue: '',
  actualDate: undefined,
};

export default DatePickerBySteps;
