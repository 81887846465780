/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { colors, colorTheme } from '../../../styles/colors';
import { Radio } from '../Radio';

const styles = {
  '.box': {
    borderRadius: 999,
    boxShadow: `0 0 0 1px ${colors.gray.light}`,
    border: `2px solid white`,
  },
  '.radio.checked .box': {
    backgroundColor: colors.cyan.dark,
  },
  '.radio.disabled .box': {
    border: `0px `,
    backgroundColor: colorTheme.hintFieldDisabled,
  },
};

const RadioButton = ({ checked, disabled, children, onChange, className }) => {
  return (
    <div css={styles} className={className}>
      <Radio
        checked={checked}
        disabled={disabled}
        toggleCheck={onChange}
        checkComponent={<div>{checked}</div>}
      >
        {children}
      </Radio>
    </div>
  );
};

RadioButton.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

RadioButton.defaultProps = {
  checked: false,
  disabled: false,
  children: undefined,
  className: undefined,
  onChange: () => {},
};

export default RadioButton;
