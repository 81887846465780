/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { titleFont } from '../../styles/typography';
import Divider from '../Divider/divider';

const styles = {
  display: 'flex',
  flexDirection: 'column',
  flexBasis: '100%',
  padding: 30,
  paddingLeft: 24,
  '.content-title': { ...titleFont('xs', 'serif'), margin: 0 },
  '.element': { flex: 'auto' },
};

const StepContent = ({ title, element }) => (
  <div css={styles}>
    <h4 className="content-title">{title}</h4>
    <Divider />
    <div className="element">{element}</div>
  </div>
);

StepContent.propTypes = {
  title: PropTypes.string,
  element: PropTypes.element,
};

export default StepContent;
