/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { cloneElement, Fragment, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const filterStyles = {
  position: 'absolute',
  top: '100%',
  zIndex: 3,
};

const clickOutsideStyles = {
  position: 'fixed',
  width: '100vw',
  height: '100vh',
  top: 0,
  left: 0,
  zIndex: 2,
};

const TableFilter = ({ filterComponent, onClose }) => {
  const filterRef = useRef(null);
  const [filterPosition, setFilterPosition] = useState({ left: null });

  useEffect(() => {
    const { left } = filterRef.current.getBoundingClientRect();
    const { innerWidth } = window;
    const filterWidth = filterRef.current.offsetWidth;
    const spaceLeft = innerWidth - left;

    if (spaceLeft < filterWidth) {
      setFilterPosition({ left: `calc(100% - ${filterWidth}px)` });
      return;
    }

    setFilterPosition({ left: '50%' });
  }, []);

  return (
    <Fragment>
      <div css={{ ...filterStyles, ...filterPosition }} ref={filterRef}>
        {filterComponent && cloneElement(filterComponent, { onClose })}
      </div>
      <div css={clickOutsideStyles} onClick={onClose} role="presentation" />
    </Fragment>
  );
};

TableFilter.propTypes = {
  filterComponent: PropTypes.element.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TableFilter;
