/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { useCallback, useRef } from 'react';
import { fieldBaseStyles } from '../../styles/baseStyles';
import { colors, colorTheme } from '../../styles/colors';
import { bodyFont } from '../../styles/typography';
import Icon from '../Icons/Icon';
import { icons } from '../Icons/icons';
import Tooltip from '../Tooltip/Tooltip';

const styles = {
  marginBottom: 16,
  '.label': {
    position: 'relative',
    '.required': {
      color: colors.red.medium,
      position: 'absolute',
      left: '-0.633em',
    },
  },
  '.label-row': {
    display: 'flex',
    justifyContent: 'space-between',
    label: fieldBaseStyles.label,
    div: {
      ...bodyFont('xs'),
      color: colors.blue.medium,
      marginBottom: 3,
    },
  },
  '.field': {
    position: 'relative',
    border: `1px dashed ${colors.gray.light}`,
    borderRadius: 4,
    color: colorTheme.textPlaceholder,
    padding: 14,
    minHeight: 50,
    width: '100%',
    ':hover,:focus': {
      backgroundColor: colorTheme.hintGhostHoverActive,
      borderColor: colorTheme.hintInteractive,
    },
    input: { opacity: 0, position: 'absolute' },
    svg: {
      position: 'absolute',
      right: 13,
      top: 12,
      bottom: 12,
    },
    '&.active,&.disabled': {
      color: colorTheme.textDefault,
    },
    '&.disabled': {
      backgroundColor: colorTheme.hintFieldDisabled,
      borderColor: colors.gray.light,
    },
    '&.error': {
      borderColor: colorTheme.hintDanger,
    },
  },
  '.message': {
    display: 'flex',
    alignItems: 'center',
    marginTop: 4,
    svg: {
      marginRight: 4,
    },
    span: {
      ...bodyFont('sm'),
      color: colorTheme.hintDanger,
    },
  },
};

const FileUpload = ({
  filename,
  fieldName,
  helpText,
  hint,
  placeholder,
  disabled,
  errorMessage,
  onChange,
  required,
}) => {
  const inputRef = useRef(null);

  const onFileDrop = useCallback(
    (files) => {
      if (disabled) {
        return;
      }
      onChange(files?.item(0));
    },
    [disabled, onChange],
  );

  const fieldClasses = ['field'];
  if (filename.length) {
    fieldClasses.push('active');
  } else {
    if (disabled) {
      fieldClasses.push('disabled');
    }
    if (errorMessage) {
      fieldClasses.push('error');
    }
  }

  const fieldText = filename.length ? filename : !disabled ? placeholder : '';

  return (
    <div css={styles}>
      <div className="label-row">
        <label className="label">
          {required && <span className="required">*</span>} {fieldName}
        </label>
        <Tooltip
          css={{ '[class*="hint--"]::after': { width: 220, textAlign: 'left' } }}
          position="top-left"
          text={helpText}
        >
          {hint}
        </Tooltip>
      </div>
      <label
        className={fieldClasses.join(' ')}
        onDrop={(e) => {
          e.preventDefault();
          onFileDrop(e.dataTransfer.files);
        }}
        onChange={(e) => {
          onFileDrop(e.target.files);
        }}
        onDragOver={(e) => {
          e.preventDefault();
        }}
      >
        {fieldText}
        <input ref={inputRef} type="file" disabled={disabled} />
        {filename.length ? (
          <span
            onClick={(e) => {
              e.preventDefault();
              inputRef.current.value = null;
              onFileDrop(null);
            }}
          >
            <Icon
              icon={icons.cross}
              color={disabled ? colorTheme.textLight : colorTheme.hintDanger}
              size="md"
            />
          </span>
        ) : (
          <Icon
            icon={icons.attachment}
            color={disabled ? colorTheme.textLight : colorTheme.interactive}
            size="md"
          />
        )}
      </label>
      {errorMessage ? (
        <div className="message">
          <Icon icon={icons.info} color={colorTheme.hintDanger} /> <span>{errorMessage}</span>
        </div>
      ) : null}
    </div>
  );
};

FileUpload.propTypes = {
  filename: PropTypes.string,
  fieldName: PropTypes.string,
  helpText: PropTypes.string,
  hint: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
};

FileUpload.defaultProps = {
  filename: '',
  fieldName: 'LABEL',
  helpText: 'Podés subir archivos en formato .pdf o .doc. Máximo 5MB.',
  hint: '¿Qué formato subo?',
  placeholder: 'Soltá tus archivos acá...',
  disabled: false,
  errorMessage: '',
  required: false,
  onChange: () => {},
};

export default FileUpload;
