/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { makeMeContainer } from '../../styles/grid';

const styles = {
  ...makeMeContainer(1128),
};

const Container = ({ children, className }) => (
  <div css={styles} className={className}>
    {children}
  </div>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Container.defaultProps = {
  className: '',
};

export default Container;
