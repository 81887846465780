import PropTypes from 'prop-types';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { colors, colorTheme } from '../../styles/colors';
import { titleFont } from '../../styles/typography';
import { respondTo } from '../../styles/respondTo';
import Icon from '../Icons/Icon';
import { icons } from '../Icons/icons';
import Button from '../Button/Button';

const styles = {
  position: 'fixed',
  zIndex: 100,
  paddingTop: 60,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  overflow: 'auto',
  backgroundColor: 'rgba(221,221,221,0.4)',
  borderRadius: 2,
  outline: 'none',
  [respondTo('xs', 'sm')]: {
    padding: 16,
  },
  '.modal-content': {
    minHeight: 'auto',
    zIndex: 200,
    backgroundColor: '#FFFFFF',
    margin: 'auto',
    padding: 24,
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.2)',
    maxWidth: 360,
    [respondTo('xs', 'sm')]: {
      width: 'initial',
      padding: 16,
    },
  },
  '.modal-close': {
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer',
    },
    '&:focus': {
      textDecoration: 'none',
      cursor: 'pointer',
    },
  },
  '.modal-header': {
    borderBottom: `1px solid ${colors.gray.lighten}`,
    marginBottom: 24,
    display: 'flex',
    paddingBottom: 24,
    justifyContent: 'space-between',
    [respondTo('xs', 'sm')]: {
      marginBottom: 16,
      paddingBottom: 16,
    },
    '&.modal-header-no-border': {
      borderBottomColor: 'transparent',
      paddingBottom: 0,
    },
  },
  '.modal-title': {
    ...titleFont('xs', 'serif'),
    color: colorTheme.textDefault,
  },
  '&.display-block': {
    display: 'block',
  },
  '&.display-none': {
    display: 'none',
  },
};

const Modal = ({ onClose, show, children, title, className }) => {
  const classname = `${className} display-${show ? 'block' : 'none'}`;
  const handleClose = (e) => {
    if (e.target.id === 'modal-parent') {
      onClose(e);
    }
  };
  return (
    <div
      css={styles}
      className={classname}
      onClick={handleClose}
      onKeyDown={handleClose}
      role="button"
      tabIndex="0"
      id="modal-parent"
    >
      <div className="modal-content">
        <div className={`modal-header ${!title ? 'modal-header-no-border' : ''}`}>
          <span className="modal-title">{title}</span>
          <Button variant="link" onClick={onClose} className="modal-close">
            <Icon icon={icons.close} color={colors.red.dark} size={16} />
          </Button>
        </div>
        {children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
};

Modal.defaultProps = {
  className: '',
};

export default Modal;
