/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import Button from '../../Button/Button';
import { range } from '../../../utils/data/range';
import { getDayRangeLimits } from './date-range-limits';

const Styles = {
  '.grid-years': {
    gridTemplateColumns: 'repeat(7, 42px)',
    gridTemplateRows: 'repeat(5, 52px)',
    display: 'grid',
    margin: 0,
    padding: '0 14px 0 14px',
    li: {
      listStyle: 'none',
      width: 60,
      height: 52,
      textAlign: 'center',
      paddingTop: 18,
    },
  },
};

const getDays = (year, month, limits, currentDate) => {
  const [daysStart, daysEnd, daysLast] = getDayRangeLimits(year, month, limits, currentDate);

  const prevDays = range(1, daysStart);
  const validDays = range(daysStart, daysEnd);
  const postDays = range(daysEnd, daysLast);

  return [prevDays, validDays, postDays];
};

const InvalidDayListButton = ({ day }) => (
  <li>
    <Button variant="link" size="sm" disabled={true}>
      {day}
    </Button>
  </li>
);

const DaySelect = ({ selectDay, year, month, limits, currentDate }) => {
  const [prevDays, validDays, postDays] = getDays(year, month, limits, currentDate);
  return (
    <div css={Styles}>
      <ul className="grid-years">
        {prevDays.map((day) => (
          <InvalidDayListButton key={day} day={day} />
        ))}
        {validDays.map((day) => (
          <li key={day}>
            {
              <Button variant="link" size="sm" onClick={() => selectDay(day)}>
                {day}
              </Button>
            }
          </li>
        ))}
        {postDays.map((day) => (
          <InvalidDayListButton key={day} day={day} />
        ))}
      </ul>
    </div>
  );
};

DaySelect.propTypes = {
  selectDay: PropTypes.func.isRequired,
  year: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
  limits: PropTypes.object.isRequired,
  currentDate: PropTypes.instanceOf(Date).isRequired,
};

InvalidDayListButton.propTypes = {
  day: PropTypes.number.isRequired,
};

export default DaySelect;
