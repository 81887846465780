import PropTypes from 'prop-types';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Link } from 'react-router-dom';
import Icon from '../Icons/Icon';
import { icons } from '../Icons/icons';
import { subtitleFont, titleFont } from '../../styles/typography';
import { colors } from '../../styles/colors';

const styles = {
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  a: {
    ...subtitleFont('sm'),
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: colors.gray.darkest,
  },
  '.arrow-icon': {
    marginLeft: 5,
    marginRight: 5,
  },
  '.level-container:last-child': {
    a: {
      ...titleFont('xs', 'serif'),
      textTransform: 'inherit',
      color: 'black',
    },
  },
};

export const Breadcrumb = ({ levels, className, onClick }) => {
  return (
    <div css={styles} className={`breadcrumb ${className}`}>
      {levels.map((level, i) => (
        <div className="level-container" key={level.description}>
          <Link to={level.to} className="level" onClick={() => onClick(level)}>
            {level.description}
          </Link>
          {i < levels.length - 1 && <Icon icon={icons.chevronRight} className="arrow-icon" />}
        </div>
      ))}
    </div>
  );
};

Breadcrumb.propTypes = {
  levels: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    }),
  ).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

Breadcrumb.defaultProps = {
  className: '',
  onClick: () => {},
};
