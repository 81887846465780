/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import { colors } from '../../styles/colors';

const styles = {
  button: {
    borderColor: colors.gray.light,
  },

  '.separated': {
    marginRight: 8,
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  '.collapsed': {
    borderRadius: 0,
    '&:first-of-type': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    '&:last-of-type': {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
    '&:not(:last-of-type)': {
      borderRight: 0,
    },
  },
  '.read-only': {
    cursor: 'default',
    '&:not(.active)': {
      '&:hover': {
        backgroundColor: 'initial',
      },
    },
  },
};

const ButtonGroup = ({
  collapsed,
  size,
  items,
  onClick,
  className,
  buttonClassName,
  initialSelected,
  multiple,
  readOnly,
}) => {
  let initialSelectedToArray = [];
  if (initialSelected instanceof Array) {
    initialSelectedToArray = multiple ? initialSelected : initialSelected.splice(0, 1);
  } else if (initialSelected instanceof Object) {
    initialSelectedToArray.push(initialSelected);
  }

  const [selected, setSelected] = useState(initialSelectedToArray);
  const spacingClass = collapsed ? 'collapsed' : 'separated';

  const selectItem = (item) => {
    if (readOnly) {
      return;
    }

    let arrItems = [...selected];
    if (multiple) {
      const itemIndex = selected.findIndex((option) => option.value === item.value);
      if (itemIndex === -1) {
        arrItems.push(item);
      } else {
        arrItems.splice(itemIndex, 1);
      }
    } else {
      arrItems = [item];
    }
    setSelected(arrItems);
    const itemsValues = multiple ? arrItems.map((option) => option.value) : item.value;
    onClick(itemsValues);
  };

  const getActiveClassName = (item) => {
    return selected.some((option) => option.value === item.value) ? 'active' : '';
  };

  return (
    <div css={styles} className={className}>
      {items.map((item, key) => (
        <Button
          key={key}
          size={size}
          className={`${buttonClassName} ${getActiveClassName(item)} ${
            readOnly ? 'read-only' : ''
          } ${spacingClass}`}
          disabled={item.disabled}
          onClick={() => selectItem(item)}
          type="button"
          variant="secondary"
        >
          {item.display}
        </Button>
      ))}
    </div>
  );
};

const itemType = PropTypes.shape({
  display: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
});

ButtonGroup.propTypes = {
  collapsed: PropTypes.bool,
  buttonClassName: PropTypes.string,
  size: PropTypes.oneOf(['lg', 'md', 'sm']),
  className: PropTypes.string,
  initialSelected: PropTypes.oneOfType([itemType, PropTypes.arrayOf(itemType)]),
  items: PropTypes.arrayOf(itemType),
  onClick: PropTypes.func,
  multiple: PropTypes.bool,
  readOnly: PropTypes.bool,
};

ButtonGroup.defaultProps = {
  collapsed: false,
  buttonClassName: '',
  size: 'md',
  className: '',
  items: [],
  initialSelected: null,
  onClick: () => {},
  multiple: false,
  readOnly: false,
};

export default ButtonGroup;
