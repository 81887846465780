/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { colorTheme } from '../../styles/colors';
import Icon from '../Icons/Icon';
import { icons } from '../Icons/icons';
import { bodyStyles, headStyles } from './styles';

const styles = {
  button: {
    cursor: 'pointer',
  },
  '&.open.allowOverflow, .content.open.allowOverflow': {
    overflow: 'visible',
    maxHeight: 'none',
  },
};

const getContentHeight = (content) => {
  let sum = 0;
  content?.current?.querySelectorAll('.inner .content .inner').forEach((element) => {
    sum += element.scrollHeight;
  });
  return content?.current?.scrollHeight + sum;
};

const Collapsible = ({
  openDefault,
  title,
  titleTag: TitleTag,
  type,
  children,
  className,
  active,
  onClick,
}) => {
  const [open, setOpen] = useState(openDefault);
  const [maxHeight, setMaxHeight] = useState(0);
  const [allowOverflow, setAllowOverflow] = useState(false);
  const content = useRef(null);
  const openClassName = open ? 'open' : 'close';
  const allowOverflowClassName = allowOverflow ? 'allowOverflow' : '';

  useEffect(() => {
    setOpen(openDefault);
  }, [openDefault]);

  useEffect(() => {
    setMaxHeight(open ? getContentHeight(content) : 0);

    if (open) {
      // We leave some time so that the open animation can play smoothly
      const timeout = setTimeout(() => setAllowOverflow(true), 500);
      return () => clearTimeout(timeout);
    } else {
      setAllowOverflow(false);
    }
  }, [open]);

  const toggle = () => {
    setOpen(!open);
    onClick();
  };

  return (
    <div
      className={`collapsible ${openClassName} ${allowOverflowClassName} ${className}`}
      css={styles}
    >
      <button
        type="button"
        css={headStyles(type)}
        className={active ? 'active' : ''}
        onClick={toggle}
      >
        <TitleTag className="title">{title}</TitleTag>
        <Icon
          icon={icons.chevronBottom}
          className={`chevron chevron-${openClassName}`}
          size="20"
          color={colorTheme.interactive}
        />
      </button>
      <div
        ref={content}
        css={bodyStyles(type, maxHeight)}
        className={`content ${openClassName} ${allowOverflowClassName} `}
      >
        <div className="inner">{children}</div>
      </div>
    </div>
  );
};

Collapsible.defaultProps = {
  openDefault: false,
  title: '',
  titleTag: 'span',
  children: undefined,
  className: undefined,
  active: false,
  onClick: () => {},
};

Collapsible.propTypes = {
  openDefault: PropTypes.bool,
  title: PropTypes.string,
  titleTag: PropTypes.oneOf(['span', 'p', 'h2', 'h3', 'div']),
  active: PropTypes.bool,
  type: PropTypes.oneOf(['simple', 'list', 'menu', 'submenu']).isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  onClick: PropTypes.func,
};

export default Collapsible;
