import PropTypes from 'prop-types';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { colors, colorTheme } from '../../styles/colors';
import { bodyFont } from '../../styles/typography';
import { tooltipStyles } from './tooltipStyles';

const styles = {
  '[class*="hint--"]:after': {
    ...bodyFont('sm', 'sans'),
    lineHeight: '16px',
    backgroundColor: 'white',
    textShadow: 'none',
    padding: 8,
    color: colorTheme.textDefault,
    border: `1px solid ${colors.gray.lighten}`,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    borderRadius: 2,
    whiteSpace: 'inherit',
    width: 125,
    textAlign: 'center',
  },
  '.hint--bottom:before, .hint--top:before': {
    left: 'calc(50% - 6px)',
  },
  '.hint--bottom:before, .hint--bottom-right:before, .hint--bottom-left:before': {
    borderBottomColor: colorTheme.interactive,
  },
  '.hint--top:before, .hint--top-right:before, .hint--top-left:before': {
    borderTopColor: colorTheme.interactive,
  },
  '.hint--left:before': {
    borderLeftColor: colorTheme.interactive,
  },
  '.hint--right:before': {
    borderRightColor: colorTheme.interactive,
  },

  '.hint--left:after, .hint--right:after': {
    marginBottom: -17,
  },
};

const Tooltip = ({ position, text, children, className }) => {
  return (
    <div css={[tooltipStyles, styles]} className={className}>
      <span className={`tooltip-span hint--${position}`} aria-label={text}>
        {children}
      </span>
    </div>
  );
};

Tooltip.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  position: PropTypes.string,
  text: PropTypes.string,
};

Tooltip.defaultProps = {
  className: '',
  children: undefined,
  position: 'bottom',
};

export default Tooltip;
