/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { Children } from 'react';
import { colors, colorTheme } from '../../styles/colors';
import StepContent from './StepContent';

const styles = {
  display: 'flex',
  '.steps': {
    backgroundColor: colors.gray.ultralight,
    button: {
      borderBottom: 'none',
      '&:first-of-type': {
        borderTop: 'none',
      },
    },
  },
  border: `${colorTheme.hintGroupingLine} solid 1px`,
  borderLeft: 'none',
  borderRadius: '0px 8px 8px 0px',
};

const StepSelector = ({ children, currentStepId, onStepSelected }) => {
  const steps = Children.toArray(children);

  const currentStep = steps.find((step) => step.props.id === currentStepId);

  return (
    <div css={styles}>
      <div className="steps">
        {steps.map((step) => {
          const Child = step.type;
          return (
            <Child
              {...step.props}
              selected={step.props.state === 'disabled' ? false : step.props.selected}
              key={step.props.id}
              onClick={onStepSelected}
            />
          );
        })}
      </div>
      {currentStep.props.state !== 'disabled' && <StepContent {...currentStep.props} />}
    </div>
  );
};

StepSelector.propTypes = {
  children(properties, propName, componentName) {
    const children = properties[propName];
    if (!children.every((child) => child.type.name === 'Step')) {
      return new Error(`${componentName} children should be of type 'Step'.`);
    }
    return null;
  },
  currentStepId: PropTypes.string.isRequired,
  onStepSelected: PropTypes.func.isRequired,
};

export default StepSelector;
