/* eslint-disable react/jsx-indent */
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { colors as udtColors, colorTheme } from '../../../styles/colors';
import { base, bodyFont } from '../../../styles/typography';
import Icon from '../../Icons/Icon';
import { icons } from '../../Icons/icons';
import { fieldBaseStyles } from '../../../styles/baseStyles';
import { normalizeRender } from '../../../utils/data/render';
import { ButtonWrapper } from '../../ButtonWrapper/ButtonWrapper';

const baseStyles = {
  border: `solid 1px ${udtColors.gray.light}`,
  borderRadius: 4,
  cursor: 'pointer',
  width: 'inherit',
  backgroundColor: 'white',
  position: 'relative',
  color: udtColors.gray.darkest,
};

const colors = {
  normal: udtColors.gray.light,
  error: udtColors.red.dark,
};

const borderError = {
  borderColor: colors.error,
  '&:focus': {
    borderColor: udtColors.cyan.dark,
  },
};

const styles = {
  fontFamily: base.fontFamily,
  marginBottom: 16,
  position: 'relative',
  width: '-webkit-fill-available',
  '&.fix-firefox': {
    width: '-moz-available',
  },
  '.label': {
    ...fieldBaseStyles.label,
    '.required': {
      color: udtColors.red.medium,
      position: 'absolute',
      left: '-0.700em',
    },
  },
  '.content': {
    '.placeholder': {
      ...bodyFont('md'),
      color: colorTheme.textPlaceholder,
      lineHeight: 'normal',
    },
  },
  '.select': {
    ...baseStyles,
    boxSizing: 'content-box',
    padding: '12px 16px',
    fontSize: 16,
    display: 'block',
    minHeight: 22,
    outline: 'none',
    '&.focus': {
      borderColor: udtColors.cyan.dark,
    },
    svg: {
      float: 'right',
      position: 'absolute',
      marginLeft: 'auto',
      right: 8,
      bottom: 12,
    },
  },
  '.clear-button': {
    position: 'absolute',
    right: 8,
    bottom: 12,
  },
  '.options': {
    ...fieldBaseStyles.options,
    position: 'absolute',
    zIndex: 5,
    '.group': {
      width: baseStyles.width,
      backgroundColor: udtColors.gray.ultralight,
      fontWeight: 600,
      fontSize: 14,
      textTransform: 'uppercase',
      cursor: 'default',
      padding: '8px 15px',
      borderTop: `1px solid ${udtColors.gray.lighten}`,
      borderBottom: `1px solid ${udtColors.gray.lighten}`,
      fontFamily: 'Zilla Slab, serif',
    },
  },
  '.click-outside': {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
    zIndex: 4,
  },
  '.message': {
    display: 'flex',
    fontSize: 13,
    marginTop: 4,
    svg: {
      marginRight: 4,
    },
  },
  '&.error': {
    '.select': {
      ...borderError,
      '&.focus': {
        borderColor: udtColors.cyan.dark,
      },
    },
    '.message': {
      span: {
        color: colors.error,
      },
    },
  },
  '.disabled': {
    cursor: 'default',
    backgroundColor: colorTheme.hintFieldDisabled,
    '&:not(.active)': {
      '&:hover': {
        backgroundColor: colorTheme.hintFieldDisabled,
      },
    },
  },
};

const OptionSelector = ({
  className,
  items,
  selected,
  label,
  required,
  placeholder,
  groups,
  error,
  onChange,
  onClick,
  onClear,
  render,
  iconColor,
  disabled,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [selectedItem, setSelectedItem] = useState(selected);

  useEffect(() => {
    setSelectedItem(selected);
  }, [selected]);

  const selectItem = (item) => {
    setSelectedItem(item);
    setShowOptions(false);
    onChange(item);
  };

  const displayMsg = () => error || null;

  const showAlert = !!error;

  const onSelectClick = () => {
    onClick(!showOptions);
    setShowOptions(!showOptions);
  };

  const normalizedRender = normalizeRender(render);

  return (
    <div css={styles} className={`${className} ${error ? 'error' : ''} fix-firefox`}>
      {label && (
        <label className="label">
          {required && <span className="required">*</span>} {label}
        </label>
      )}
      <div
        className={`select ${showOptions ? 'focus' : ''} ${disabled ? 'disabled' : ''}`}
        onClick={disabled ? null : onSelectClick}
        onKeyDown={disabled ? null : onSelectClick}
        role="button"
        tabIndex="0"
      >
        <div className="content">
          <div
            className={selectedItem === null ? 'placeholder' : ''}
            data-content={
              selectedItem !== null && typeof normalizedRender(selectedItem) === 'string'
                ? normalizedRender(selectedItem)
                : ''
            }
          >
            {selectedItem === null ? placeholder : normalizedRender(selectedItem)}
          </div>

          {!disabled && (!onClear || (onClear && !selectedItem)) && (
            <Icon icon={icons.chevronBottom} size={24} color={iconColor} />
          )}
        </div>
      </div>
      {!disabled && selectedItem && onClear && (
        <ButtonWrapper
          onClick={() => {
            setSelectedItem(null);
            onClear();
          }}
          className={'clear-button'}
        >
          <Icon icon={icons.close} size={24} color={colorTheme.hintDanger} />
        </ButtonWrapper>
      )}

      {showOptions && (
        <div className="options">
          {groups
            ? groups.map((group, key) => (
                <div key={key}>
                  <div className="group">{group.name}</div>
                  {group.items.map((item, key2) => (
                    <div
                      key={key2}
                      className="option"
                      onClick={() => selectItem(item)}
                      onKeyDown={() => selectItem(item)}
                      role="button"
                      tabIndex="0"
                    >
                      {normalizedRender(item)}
                    </div>
                  ))}
                </div>
              ))
            : items.map((item, key) => (
                <div
                  key={key}
                  className="option"
                  onClick={() => selectItem(item)}
                  onKeyDown={() => selectItem(item)}
                  role="button"
                  tabIndex="0"
                >
                  {normalizedRender(item)}
                </div>
              ))}
        </div>
      )}
      {showAlert && (
        <span className="message">
          <Icon icon={icons.error} color={colors.error} />
          <span>{displayMsg()}</span>
        </span>
      )}
      {showOptions && (
        <div
          className="click-outside"
          onClick={() => setShowOptions(!showOptions)}
          role="presentation"
        />
      )}
    </div>
  );
};

OptionSelector.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  error: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
  ),
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      items: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
    }),
  ),
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onClear: PropTypes.func,
  render: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  iconColor: PropTypes.string,
  disabled: PropTypes.bool,
};

OptionSelector.defaultProps = {
  className: 'optionSelector',
  placeholder: '',
  selected: null,
  required: false,
  onChange: () => {},
  onClick: () => {},
  items: [],
  groups: undefined,
  error: null,
  render: null,
  iconColor: colorTheme.interactive,
  disabled: false,
};

export default OptionSelector;
