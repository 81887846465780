/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { respondTo } from '../../styles/respondTo';
import { titleFont, bodyFont } from '../../styles/typography';
import Divider from '../Divider/divider';

const styles = {
  display: 'flex',
  flexDirection: 'row',
  '.text-container': {
    ...titleFont('xs', 'serif'),
    paddingLeft: 50,
    paddingTop: 250,
    width: '40%',

    '.title-header': {
      opacity: 0.4,
      textTransform: 'uppercase',
      ...bodyFont('md'),
    },
    '.title': {
      textAlign: 'left',
      marginBottom: 32,
      ...titleFont('md', 'serif'),

      [respondTo('xs', 'sm')]: {
        paddingTop: 24,
      },
    },
  },
  '.img-container-dot': {
    textAlign: 'left',
    marginTop: 250,
    marginBottom: 32,
    img: {
      width: 80,
    },
  },

  '.img-container': {
    width: '50%',
    textAlign: 'right',
    marginBottom: 32,
    img: {
      width: '100%',
      [respondTo('xs', 'sm')]: {
        width: '100%',
      },
    },
  },

  a: {
    fontWeight: 700,
  },
  [respondTo('xs', 'sm')]: {
    padding: 16,
    flexDirection: 'column',
    '.img-container-dot': {
      display: 'none',
    },
    '.divider': {
      display: 'none',
    },
    '.text-container': {
      margin: 0,
      padding: 16,
      textAlign: 'center',
      width: '100%',
      '.title-header': {
        ...titleFont('xs', 'serif'),
        textTransform: 'uppercase',
      },
      '.title': {
        margin: 0,
        textAlign: 'center',
        width: '100%',
      },
    },
    '.img-container': {
      width: '100%',
    },
  },
};

const InactiveDefaultPage = ({ title, header }) => {
  return (
    <div css={styles} data-testid="error-page-component">
      <div className={'img-container-dot'}>
        <img
          src={'https://branding.123seguro.com/miscellaneous/pattern-dots-hero.svg'}
          alt={'empty'}
        />
      </div>
      <div className={'text-container'}>
        <h4 className={'title-header'}>{header}</h4>
        <div className={'divider'}>
          <Divider />
        </div>
        <h1 className={'title'}>{title}</h1>
      </div>

      <div className={'img-container'}>
        <img
          src={'https://branding.123seguro.com/illustration/illus_people_working.svg'}
          alt={'empty'}
        />
      </div>
    </div>
  );
};

InactiveDefaultPage.propTypes = {
  title: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
};

export default InactiveDefaultPage;
