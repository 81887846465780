/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { colors, colorTheme } from '../../styles/colors';
import { bodyFont } from '../../styles/typography';
import { validatePropValue } from '../../utils/ErrorMsg';
import Icon from '../Icons/Icon';
import { icons } from '../Icons/icons';

const Toggle = ({ size, disabled, checked, label, toggleCallback, className }) => {
  const [isChecked, setIsChecked] = useState(checked);

  const getSize = (component) => {
    const sizes = {
      'toggle-switch': {
        lg: { width: 78, height: 36 },
        md: { width: 52, height: 24 },
        sm: { width: 32, height: 16 },
      },
      'toggle-slider': {
        lg: { width: 48, height: 48, top: -7 },
        md: { width: 32, height: 32, top: -5 },
        sm: { width: 20, height: 20, top: -3 },
      },
      translateX: {
        lg: 30,
        md: 21,
        sm: 12,
      },
    };
    validatePropValue(sizes[component][size], 'Toggle', 'size', size);
    return sizes[component][size] ? sizes[component][size] : undefined;
  };

  const getIconColor = () => {
    if (!disabled) {
      return 'white';
    } else if (isChecked) {
      return colorTheme.hintSuccess;
    }
    return colors.blue.dark;
  };

  const styles = {
    display: 'flex',
    alignItems: 'center',
    '.toggle-switch': {
      ...getSize('toggle-switch'),
      position: 'relative',
      borderRadius: 24,
      cursor: !disabled ? 'pointer' : 'default',
      backgroundColor: !disabled ? colors.blue.lightest : colorTheme.hintGhostDisabled,
      borderWidth: 1,
      borderColor: !disabled ? colors.gray.medium : colors.gray.lightest,
      borderStyle: 'solid',
      marginRight: 12,
    },
    '.toggle-slider': {
      ...getSize('toggle-slider'),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      position: 'absolute',
      left: -1,
      backgroundColor: !disabled ? colors.blue.dark : colors.blue.lightest,
      transition: '.3s',
    },
    '.checked': {
      backgroundColor: !disabled ? colors.green.lightest : colorTheme.hintGhostDisabled,

      '.toggle-slider': {
        backgroundColor: !disabled ? colors.green.dark : colors.green.lightest,
        transform: `translateX(${getSize('translateX')}px)`,
      },
    },
    label: bodyFont('md'),
    input: {
      opacity: 0,
      width: 0,
      height: 0,
    },
  };

  return (
    <label css={styles} className={className}>
      <input
        disabled={disabled}
        type="checkbox"
        onChange={() => {
          toggleCallback(!isChecked);
          setIsChecked(!isChecked);
        }}
      />
      <div className={`toggle-switch ${isChecked && 'checked'}`}>
        <span className="toggle-slider">
          <Icon icon={isChecked ? icons.check : icons.cross} color={getIconColor()} size={size} />
        </span>
      </div>
      <span>{label}</span>
    </label>
  );
};

Toggle.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  label: PropTypes.string.isRequired,
  toggleCallback: PropTypes.func,
};

Toggle.defaultProps = {
  className: '',
  toggleCallback: () => {},
  size: 'sm',
  disabled: false,
  checked: false,
};

export default Toggle;
