/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../styles/colors';
import { icons } from '../Icons/icons';
import Icon from '../Icons/Icon';
import { ButtonWrapper } from '../ButtonWrapper/ButtonWrapper';
import TableFilter from './TableFilter';

const tableHeaderStyles = {
  position: 'relative',
  '.sort-icons': {
    display: 'flex',
    flexDirection: 'column',
  },
  '.icon-style-disable': {
    path: {
      fill: 'white',
      stroke: colors.cyan.dark,
      strokeWidth: '2px',
    },
  },
  '.icon-style-active': {
    path: {
      fill: colors.cyan.dark,
    },
  },
};

export const TableHeader = ({ title, id, onOrderClick, filter, activeFilterIcon, width }) => {
  const [showAsc, setShowAsc] = useState(true);
  const [showDesc, setShowDesc] = useState(true);
  const [showFilter, setShowFilter] = useState(false);

  const orderAsc = () => {
    setShowAsc(false);
    onOrderClick(id, 'asc');
    setShowDesc(true);
  };

  const orderDesc = () => {
    setShowDesc(false);
    onOrderClick(id, 'desc');
    setShowAsc(true);
  };

  return (
    <th css={tableHeaderStyles} width={width}>
      <div>{title}</div>
      <div className="icons">
        {!!onOrderClick && !filter && (
          <div className="sort-icons">
            {showAsc && (
              <ButtonWrapper onClick={orderAsc}>
                <Icon icon={icons.chevronTop} size={10} color={colors.cyan.dark} />
              </ButtonWrapper>
            )}
            {showDesc && (
              <ButtonWrapper onClick={orderDesc}>
                <Icon icon={icons.chevronBottom} size={10} color={colors.cyan.dark} />
              </ButtonWrapper>
            )}
          </div>
        )}
        {!!filter && (
          <Fragment>
            <ButtonWrapper
              onClick={() => {
                setShowFilter(!showFilter);
              }}
            >
              <Icon
                className={
                  showFilter || activeFilterIcon ? 'icon-style-active' : 'icon-style-disable'
                }
                icon={icons.filter}
                size={15}
                color={colors.cyan.dark}
              />
            </ButtonWrapper>
            {showFilter && (
              <TableFilter filterComponent={filter} onClose={() => setShowFilter(false)} />
            )}
          </Fragment>
        )}
      </div>
    </th>
  );
};

TableHeader.defaultProps = {
  width: null,
};

TableHeader.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onOrderClick: PropTypes.func.isRequired,
  filter: PropTypes.element,
  activeFilterIcon: PropTypes.bool,
  width: PropTypes.number,
};
